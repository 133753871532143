import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, DatePicker, Input, Select } from '../../components';
import { axios, Constants, Globals } from '../../utils';
import TrashIcon from '../../assets/icons/trash.png';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';

const TAB = {
  SELECTED: 1,
  UNPAID: 2,
}

class ModalCreatePayment extends React.Component {

  state = {
    tab: TAB.SELECTED,
    sellers: this.props.sellers,
    form: {
      web: true,
      seller_id: '',
      payment_date: '',
      search: '',
      since: '',
      until: '',
      order_id: '',
      orders: [],
      registered_by: this.props.user.id,
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      totalAmount: 0,
      amount_seller: 0,
      amount_manager: 0,
      to_pay: 0,
    },
    unpaidOrdersOriginal: [],
    unpaidOrders: [],
    pagination: {
      page: 1,
      isLastPage: true,
    },
    totalAmount: 0,
  }

  getUnpaidOrders = async (page = 1) => {
    Globals.setLoading();
    await this.setState({ unpaidOrders: [] });

    try {
      const { form } = this.state;

      const data = {
        web: true,
        user_id: this.props.user.id,
        seller_id: form.seller_id,
        until: form.until && moment(form.until,'DD-MM-YYYY').format('YYYY-MM-DD'),
        since: form.since && moment(form.since,'DD-MM-YYYY').format('YYYY-MM-DD'),
        currency: Constants.CURRENCIES.DOLARES,
        status: [
          Constants.ORDER_STATUS.PENDING,
          Constants.ORDER_STATUS.PROCESSED,
        ],
        page,
        createPaymentComission: true,
        only_processed: true,
      };

      const res = await axios.post('admin/reports/orders', data);

      this.setState({
        unpaidOrders: res.data.orders.data,
        unpaidOrdersOriginal: res.data.orders.data,
        pagination: {
          page,
          isLastPage: page >= res.data.orders.last_page,
        },
      });

    } catch (error) {
      Globals.showError();
      console.log('Commissions -> load -> catch: ', error);
    }

    Globals.quitLoading();
  }

  filterOrders = async () => {
    const { unpaidOrdersOriginal, form } = this.state;
    let filterOrders = unpaidOrdersOriginal;
  
    switch (true) {
      case form.order_id !== '':
        const orderId = parseInt(form.order_id, 10);
        filterOrders = unpaidOrdersOriginal.filter(function(item) {
          return item.correlative_number.toString().startsWith(orderId.toString());
        });
        break;
      case form.since !== '':
        const sinceDate = moment(form.since).format('YYYY-MM-DD');
        filterOrders = unpaidOrdersOriginal.filter(function(item) {
          const createdAtDate = moment(item.created_at).format('YYYY-MM-DD');
          return createdAtDate >= sinceDate;
        });
        break;
      case form.until !== '':
        const untilDate = moment(form.until).format('YYYY-MM-DD');
        filterOrders = unpaidOrdersOriginal.filter(function(item) {
          const createdAtDate = moment(item.created_at).format('YYYY-MM-DD');
          return createdAtDate <= untilDate;
        });
        break;
      default:
        break;
    }
  
    if(form.order_id !== ''){
      if (filterOrders.length <= 0) {
        this.setState({
          unpaidOrders: unpaidOrdersOriginal,
        });
      } else {
        this.setState({
          unpaidOrders: filterOrders,
        });
      }
    }
    else{
      this.setState({
        unpaidOrders: filterOrders,
      });
    }
    
  }

  change = async (value, target) => {
    await this.setState(state => ({
      form: {
        ...state.form,
        [target]: value,
        orders: target === 'seller_id'
          ? []
          : state.form.orders,
      },
    }));
    
  }

  onSelectOrders = () => {
    if (!this.state.form.seller_id)
      return Globals.showError('Primero seleccione un vendedor');

    this.setState({ tab: TAB.UNPAID });
  }

  toggleItem = async (item) => {
    const { form } = this.state;
    const index = form.orders.findIndex(x => x.id === item.id);

    if (index >= 0) {
      this.removeItem(index);
    } else {
      await this.setState(state => ({
        form: { ...state.form, orders: [ item ] }
      }));
    }

    this.calculate();
  }

  removeItem = (index) => {
    const orders = this.state.form.orders.filter((_,i) => i !== index);
    this.setState(state => ({
      form: { ...state.form, orders }
    }));
  }

  calculate = () => {
    this.setState(state => {
      const total = state.form.orders.reduce((acc, curr) => {
        // if (curr.status_payment === Constants.PAYMENT_TYPES.TO_PAY) {
        //   const commissionVendedor = ((curr.total - curr.to_pay) / curr.total) * curr.commission;
        //   const commissionGerente = ((curr.total - curr.to_pay) / curr.total) * curr.commission_manager;
        //   return acc + commissionVendedor + commissionGerente;
        // } else {
        //   return acc + curr.commission + curr.commission_manager;
        // }
        return curr.total_commission_to_pay.seller + curr.total_commission_to_pay.manager;
      }, 0);
      const total_seller = state.form.orders.reduce((acc, curr) => {
        return curr.total_commission_to_pay.seller;
      }, 0);
      const total_manager = state.form.orders.reduce((acc, curr) => {
        return curr.total_commission_to_pay.manager;
      }, 0);
      const total_topay = state.form.orders.reduce((acc, curr) => {
        return curr.total_commission_to_pay.total;
      }, 0);
      return {
        totalAmount: parseFloat(total).toFixed(2),
        amount_seller: parseFloat(total_seller).toFixed(2),
        amount_manager: parseFloat(total_manager).toFixed(2),
        to_pay: parseFloat(total_topay).toFixed(2),
      };
    });
  }

  submit = async () => {
    const { form, totalAmount, amount_seller, amount_manager, to_pay  } = this.state;
    
    if(!form.payment_date){
      Globals.showError('La fecha de pago es obligatoria');
      return;
    }

    Globals.setLoading();
    try {
      let order_ids = form.orders.map(i => i.id);

      const data = {
        web: true,
        user_id: form.seller_id,
        seller_id: form.seller_id,
        registered_by_id: form.registered_by,
        admin_id: form.admin_id,
        date: form.payment_date && moment(form.payment_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
        orders: JSON.stringify(order_ids),
        totalAmount: totalAmount,
        amount_seller: amount_seller,
        amount_manager: amount_manager,
        to_pay: to_pay,
      };

      await axios.post('web/admin/commission-payments/create', data);
      Globals.showSuccess('Se ha registrado el pago correctamente')
      this.props.onClose()

    } catch (error) {
      Globals.showError('Ha ocurrido un error');
      console.log('Comisiones registrar pago - catch: ', error);
    }

    Globals.quitLoading();
  }

  render() {

    const { tab, form, sellers, unpaidOrders, pagination, totalAmount } = this.state;

    return (
      <div id="commissions-payments-modal">
        {tab === TAB.SELECTED && (
          <div id="tab-selected">
            <div>
              <div>
                <Select
                  color="white"
                  label="Vendedor"
                  className="material"
                  onChange={async (e) => {
                    await this.change(e.target.value, 'seller_id');
                    this.getUnpaidOrders();
                  }}
                  value={form.seller_id}
                  options={sellers.map(item => ({
                    value: item.id,
                    label: item.name
                  }))}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <DatePicker
                    color="white"
                    label="Fecha de pago"
                    className="material"
                    value={form.payment_date}
                    onChange={v => this.change(v, 'payment_date')}
                  />
                </div>
                <div className="col-6 d-flex justify-content-end align-items-center">
                  <div>
                    <Button onClick={this.onSelectOrders}>
                      Seleccionar Pedidos
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
              {form.orders.map((order, index) => (
                <div key={`order-${index}`} className="order">
                  <div className="details">
                    <p><span>ID:</span> { order.number_format }</p>
                    <p><span>Fecha:</span> { moment(order.created_at).format('DD/MM/YYYY HH:mm') }</p>
                    {/* <p><span>Total Vendedor:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission) : order.commission, true)}</p>
                    <p><span>Total Gerente:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission_manager) : order.commission_manager, true) }</p> */}
                    <p><span>Total Vendedor:</span> $ {  Globals.formatMiles(order.total_commission_to_pay.seller, true)}</p>
                    <p><span>Total Gerente:</span> $ { Globals.formatMiles(order.total_commission_to_pay.manager, true) }</p>
                  </div>
                  <div className="actions">
                    <img
                      src={TrashIcon}
                      title="Eliminar"
                      alt="Eliminar"
                      onClick={() => this.toggleItem(order)}
                    />
                  </div>
                </div>
              ))}

              {!form.orders.length && (
                <p className="empty-message">No se han seleccionado pedidos</p>
              )}
            </div>

            <div className="totals">
              <span>Total:</span>
              <span>$ { totalAmount }</span>
            </div>

            <footer>
              <Button onClick={this.submit}>
                Guardar
              </Button>
            </footer>
          </div>
        )}

        {tab === TAB.UNPAID && (
          <div id="tab-unpaid">
            <div>
              <div className="row">
                <div className="col-12">
                  <Input
                    color=" "
                    label="Buscar"
                    placeholder="Buscar por ID de pedido"
                    className="filter"
                    value={form.order_id}
                    onKeyPress={Globals.soloNumeros}
                    onChange={async (e) => {
                      await this.change(e.target.value, 'order_id');
                      this.filterOrders();
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <DatePicker
                    color="white"
                    label="Desde"
                    className="material"
                    labelClass="material"
                    value={form.since}
                    onChange={async (v) => {
                      await this.change(v, 'since');
                      this.filterOrders();
                    }}
                  />
                </div>
                <div className="col-4">
                  <DatePicker
                    color="white"
                    label="Hasta"
                    className="material"
                    labelClass="material"
                    value={form.until}
                    onChange={async (v) => {
                      await this.change(v, 'until');
                      this.filterOrders();
                    }}
                  />
                </div>
              </div>
            </div>

            <nav>
              <div className="back-button">
                <img
                  src={LeftArrowIcon}
                  alt="Regresar"
                  onClick={
                    () => 
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        since: "",
                        until: "",
                        order_id: "",
                      },
                      tab: TAB.SELECTED,
                      unpaidOrders: state.unpaidOrdersOriginal,
                    }))
                  }
                />
              </div>

              <Button
                color="green"
                onClick={() => this.setState({ tab: TAB.SELECTED })}
              >
                Guardar
              </Button>
            </nav>

            <div className="content">
              {unpaidOrders.map((order, index) => {
                const isSelected = form.orders.some(x => x.id === order.id);
                return (
                  <div
                    key={`unpaid-order-${index}`}
                    className={`order ${isSelected ? 'selected' : ''}`}
                    onClick={() => this.toggleItem(order)}
                  >
                    <div className="details">
                      <p><span>ID:</span> { order.number_format }</p>
                      <p><span>Fecha:</span> { moment(order.created_at).format('DD/MM/YYYY HH:mm') }</p>
                      {/* <p><span>Total Vendedor:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission) : order.commission, true)}</p>
                      <p><span>Total Gerente:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission_manager) : order.commission_manager, true) }</p> */}
                      <p><span>Total Vendedor:</span> $ {  Globals.formatMiles(order.total_commission_to_pay.seller, true)}</p>
                      <p><span>Total Gerente:</span> $ { Globals.formatMiles(order.total_commission_to_pay.manager, true) }</p>
                    </div>
                  </div>
                )
              })}

              {!unpaidOrders.length && (
                <p className="empty-message">No hay pedidos de este vendedor</p>
              )}
            </div>

            <footer>
              {!pagination.isLastPage && (
                <Button onClick={() => this.getUnpaidOrders(pagination.page + 1)}>
                  Cargar más
                </Button>
              )}
            </footer>
          </div>
        )}
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalCreatePayment)