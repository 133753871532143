import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Input,
  Select,
  CheckBox,
  Icon,
  DatePicker,
} from "../../components";
import { axios, Constants, Globals, Format, ENV } from "../../utils";
import { Branch, Warehouse, Category, Brand } from "../../services";
import NumberFormat from "react-number-format";
import SearchIcon from '../../assets/icons/search.png';
import PrinterIcon from '../../assets/icons/printer.png';
import moment from "moment";

const HEADER_TABLE = [
  "N° Pedido",
  "Fecha de\nEmisión",
  "Cliente",
  "Total\nPedido",
  "Abonos",
  "Comisión\nPagada",
  "Comisión\nPor Pagar",
];

const HEADER_TABLE_DEFAULT = [
  "Vendedor",
  "Total\nPedido",
  "Abonos",
  "Comisión\nPagada",
  "Comisión\nPor Pagar",
];

class ReportSellers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thead: [],
      trows: [],
      total: 0,
      header: [
        {
          value: "seller",
          label: "Vendedor",
          type: 1,
          section: "required",
          order: 0
        },
        {
          value: "total",
          label: "Total ($)",
          type: 1,
          section: "required",
          order: 1
        },
        {
            value: "total_bs",
            label: "Total (Bs)",
            type: 1,
            section: "required",
            order: 1
        },
      ],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
      page: 1,
      last_page: 1,
      data: [],
      branches: [],
      warehouses: [],
      user: null,
      view: false,
      providers: {},
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        code: true,
        name: true,
        current_stock: true,
        total: false,
        price: false,
        price_max: false,
        price_offer: false,
        cost: false,
        committed: false,
        available: false,
        since: "",
        until: "",
        search_seller: "",
        search_client: "",
        seller_id: "",
        seller_label: "",
      },
      categories: [],
      subcategories: [],
      brands: [],
      models: [],
      countPrice: 0,
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
      showProductsFilters: true,
      dates: {
        minDate: "",
        maxDate: ""
      },
      sellers: [],
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getThead();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.getSellers();
    this.orders_sellers();
  };

  getSellers = () => {
    let { form } = this.state;
    const { user } = this.props;

    axios
      .post("admin/reports/sellers-web", {user_id: user.id, only_sellers: true})
      .then(res => {
        this.setState({
          sellers: res.data.sellers,
          //data: [],
        });
      })
      .catch(err => {
        Globals.showError();
      });
  }

  orders_sellers = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    form.user_id = user.id;
    form._paginate = true;

    axios
      .post("admin/reports/sellers-web?page=" + page, form)
      .then(res => {
        const filteredOrders = res.data.sellers.data.filter(item => item.hasOwnProperty('order_id'));
        this.setState({
          last_page: res.data.sellers.last_page,
          data: filteredOrders,
        });
      })
      .catch(err => {
        Globals.showError();
      });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getWarehouses = id => {
    if (!id) {
      let warehouses_filtered = [];
      this.setState(state => ({
        ...state,
        warehouses: warehouses_filtered,
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return;
    }

    let { user } = this.props;

    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouses({
      role: user.role,
      user_id: enterpriseId,
      branch_id: id
    }).then(response => {
      if (response.result) {
        let warehouses_filtered = [];
        if (user.role === 4) {
          warehouses_filtered = response.warehouses.data.filter(el => {
            return user.assigned_warehouses.some(f => {
              return parseInt(f.warehouse_id) === parseInt(el.id);
            });
          });
        } else {
          warehouses_filtered = response.warehouses.data;
        }

        this.setState(state => ({
          warehouses: warehouses_filtered,
          form: {
            ...state.form,
            warehouse_id: ""
          }
        }));
      }
    });
  };

  getCategories = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };

    const { form } = this.state;

    Globals.setLoading();
    axios
      .post("admin/categories/get", param)
      .then(async res => {
        if (res.data.result) {
          this.setState({
            categories: res.data.categories,
            form: {
              ...form,
              subcategory_id: ""
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getSubcategories = categoryId => {
    let param = {
      category_id: categoryId,
      select: true
    };
    Category.getSubcategories(param).then(response => {
      if (response.result) {
        this.setState(state => ({
          ...state,
          subcategories: response.subcategories,
          form: {
            ...state.form,
            subcategory_id: ""
          }
        }));
      }
    });
  };

  getBrands = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };
    const { form } = this.state;
    Brand.getBrands(param).then(response => {
      if (response.result) {
        this.setState({
          brands: response.brands,
          form: {
            ...form,
            model_id: "",
            brand_id: ""
          }
        });
      }
    });
  };

  getModels = brandId => {
    let param = {
      brand_id: brandId,
      select: true,
      user_id: this.props.user.id
    };
    const { form } = this.state;
    Brand.getModels(param).then(response => {
      if (response.result) {
        this.setState({
          models: response.models,
          form: {
            ...form,
            model_id: ""
          }
        });
      }
    });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };
  openProductsDetails = e => {
    this.change(e);
    if (e.target.value === "")
      this.setState({
        showProductsFilters: false
      });
    else
      this.setState({
        showProductsFilters: true
      });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleChangeSellers = (event) => {
    const selectedValue = event.target.value;
    const selectedLabel = this.state.sellers.find((seller) => seller.id == selectedValue)?.name;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        seller_id: selectedValue,
        seller_label: selectedLabel,
      }
    }), () => {
      this.orders_sellers();
    });
  }

  handleCheck = async e => {
    var { name, checked } = e.target;
    let { form, header, trows } = this.state;

    let sectionPrices = header
      .filter(Item => {
        return Item.section === "price";
      })
      .map(Item => {
        return Item.value;
      });

    let sectionStock = header
      .filter(Item => {
        return Item.section === "stock";
      })
      .map(Item => {
        return Item.value;
      });

    if (trows.length === 0) {
      trows = header.filter(Item => {
        return Item.type === 1;
      });
    } else {
      trows = trows.filter(Item => {
        return Item.type;
      });
    }

    let find = header.find(Item => Item.value === name);

    if (sectionPrices.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        trows.push(find);

        if (countSecPrice.length === 0) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = name;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        if (countSecPrice.length === 1) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = countSecPrice[0].value;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    if (sectionStock.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        trows.push(find);

        if (countSecStock.length === 0) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        if (countSecStock.length === 1) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    trows.sort((a, b) => parseInt(a.order) - parseInt(b.order));

    let thead = trows.map(Item => {
      return Item.label;
    });

    this.setState({
      form: {
        ...form,
        [name]: checked
      }
    });

    this.getThead(thead, trows);
    return;
  };

  getThead = async (arr = [], arr2 = []) => {
    const { header } = this.state;

    let newHeader = arr;

    if (newHeader.length === 0) {
      newHeader = header
        .filter(Item => {
          return Item.type === 1;
        })
        .map(Item => {
          return Item.label;
        });
    }

    await this.setState(state => {
      state.thead = newHeader;
      state.trows = arr2;
    });
  };

  getBranches = () => {
    let { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Branch.getBranchesByEnterprise({ Id: enterpriseId })
      .then(response => {
        let branches_filtered = response.filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const branchesMap = branches_filtered
          .filter(({ status }) => status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        this.setState({
          branches: branchesMap
        });
      })
      .catch(() => Globals.showError());
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        search_seller: "",
        search_client: "",
        seller_id: "",
        seller_label: "",
      },
      thead: [],
      trows: [],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
    }));
    await this.getThead();
    // await this.getBranches();
    // await this.getCategories();
    // await this.getBrands();
    await this.load();
  };

  print = (data) => {
    axios
      .post("admin/reports/sellers-web/download", this.state.form)
      .then(res => {
        if(res.data.url_storage){
          //const url = res.data.url;
          const url = `${ENV.BasePublic}${res.data.url_storage}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
    
  };

  render() {
    const { trows } = this.state;
    const showTotal = trows.find(i => i.value == 'total') && trows.find(i => i.value == 'cost');

    return (
      <Menu history={this.props.history}>
        <div id="products">
          <div className="row">
            <div className="col-md-2">
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.handleChangeSellers}
                value={this.state.form.seller_id}
                options={this.state.sellers.map(Item => ({
                  value: Item.id,
                  label: Item.name
                }))}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            {/* <div className="col-md-2">
              <Input
                color=" "
                name="search_seller"
                label="Nombre vendedor o ID"
                className="filter"
                onChange={this.change}
                value={this.state.form.search_seller}
                placeholder="Nombre o ID"
              />
            </div> */}
            <div className="col-md-2">
              {this.state.form.seller_id !== "" ? (
                <Input
                  color=" "
                  name="search_client"
                  label="Nombre cliente"
                  className="filter"
                  onChange={this.change}
                  value={this.state.form.search_client}
                  placeholder="Nombre cliente"
                />
              ) : null}
            </div>

            <div className="col-md-4 d-flex" style={{ gap: '2rem' }}>
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.orders_sellers}>
                <span>Filtrar</span>
              </Button>
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>
          </div>
          <div className="row" id="row1">
            <div className="col col-md d-flex flex-wrap justify-content-between align-items-center check">
              {this.state.optionals.map(el => {
                if (!el.show) {
                  return (
                    <div className="px-3 py-3 check1">
                      <CheckBox
                        label={el.label}
                        name={el.value}
                        checked={this.state.form[el.value] ? true : false}
                        value={this.state.form[el.value]}
                        onChange={this.handleCheck}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title={this.state.form.seller_label !== '' ? this.state.form.seller_label : 'Reporte de Vendedores'}
            header={this.state.form.seller_id ? HEADER_TABLE : HEADER_TABLE_DEFAULT}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print(this.state.data)}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                this.state.form.seller_id ?
                  <tr key={index}>
                    <td style={{ maxWidth: 'none' }}>{i.order_id}</td>
                    <td style={{ maxWidth: 'none' }}>{i.created}</td>
                    <td style={{ maxWidth: 'none' }}>{i.client}</td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.total)).toFixed(2)}
                    </td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.abonos)).toFixed(2)}
                    </td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.commission_paid)).toFixed(2)}
                    </td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.commission_to_pay)).toFixed(2)}
                    </td>
                  </tr>
                :
                  <tr key={index}>
                    <td style={{ maxWidth: 'none' }}>{i.seller}</td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.total)).toFixed(2)}
                    </td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.abonos)).toFixed(2)}
                    </td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.commission_paid)).toFixed(2)}
                    </td>
                    <td style={{ maxWidth: 'none' }}>
                      {parseFloat(Number(i.commission_to_pay)).toFixed(2)}
                    </td>
                  </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ReportSellers);
