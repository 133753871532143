/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Button, Icon, Table } from '../../components';
import { ENV, Globals, axios } from '../../utils';
import './APIguide.css';
import AddIcon from '../../assets/icons/add.png';

const TABS = {
  ONBOARDING: 1,
  TOKEN: 2,
  ENDPOINT: 3,
}

const APIGuide = () => {
  const user = useSelector(state => state.user);
  const [tab, setTab] = useState(TABS.ONBOARDING);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [viewTokenResp, setViewTokenResp] = useState(200);
  const { tokens, generateToken, deleteToken } = useFetch(user.id);

  const showEndpointInfo = (endpoint) => {
    setTab(TABS.ENDPOINT);
    setSelectedEndpoint(endpoint);
  };

  const endpoints = [
    {
      title: 'Clientes',
      url: '/api/admin/usersaccess/clients',
      method: 'GET',
      description: 'Obtiene los registros de sus clientes.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre, DNI/RIF o email del cliente.' },
        { key: 'status', type: 'integer', isRequired: false, description: 'Busca por status [0: Inactivo, 1: Activo]'},
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>"
      },
      exampleResponse: {
        "result": true,
        "clients": [
          {
            "id": 34,
            "seller_id": null,
            "name": "yorman blanco",
            "phone": "414786311",
            "email": "yormanb@gmail.com",
            "address": "test",
            "comments": null,
            "document": "26849789",
            "zone_id": 20,
            "state_id": null,
            "latitude": null,
            "longitude": null,
            "status": 1,
            "created_at": "2023-04-05 13:26:44",
            "updated_at": "2023-04-05 13:26:44",
            "deleted_at": null,
            "seller": null
          }
        ]
      }
    },
    {
      title: 'Clientes',
      url: '/api/admin/usersaccess/clients/create',
      method: 'POST',
      description: 'Registra un cliente',
      exampleBody: {
        "token": "<aqui_su_token>",
        "latitude": 10.2493543,
        "longitude": -67.5858947,
        "name": "Yorman Blanco",
        "address": "C.C. Parque Aragua, Maracay, Aragua, Venezuela",
        "document": "J14759846",
        "email": "yormancliente22@gmail.com",
        "phone": "0412789415",
        "zone_id": 9
      },
      exampleResponse: {
        "result": true,
        "client": {
          "latitude": 10.2493543,
          "longitude": -67.5858947,
          "name": "Yorman Blanco",
          "address": "C.C. Parque Aragua, Maracay, Aragua, Venezuela",
          "document": "J14759846",
          "email": "yormancliente22@gmail.com",
          "phone": "0412789415",
          "zone_id": 9,
          "updated_at": "2023-08-01 13:51:33",
          "created_at": "2023-08-01 13:51:33",
          "id": 108,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'latitude', type: 'number', isRequired: false, description: 'Latitud del cliente.' },
        { key: 'longitude', type: 'number', isRequired: false, description: 'Longitud del cliente.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre del cliente.' },
        { key: 'address', type: 'string', isRequired: false, description: 'Dirección del cliente.' },
        { key: 'document', type: 'string', isRequired: true, description: 'Identificación del cliente. Formato de ejemplo: (V23456719 / J10789360).' },
        { key: 'email', type: 'string', isRequired: true, description: 'Email del cliente, debe ser único por cliente.' },
        { key: 'phone', type: 'string', isRequired: false, description: 'Número teléfonico del cliente.' },
        { key: 'zone_id', type: 'number', isRequired: false, description: 'ID de zona del cliente. (Obtener del api /api/admin/usersaccess/zones).' },
      ],
    },
    {
      title: 'Productos',
      url: '/api/admin/usersaccess/products',
      method: 'GET',
      description: 'Obtiene sus productos',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre, código o descripción del producto.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "products": [
          {
            "id": 90,
            "brand_id": 14,
            "model_id": 9,
            "code": "3456256",
            "name": "Audifonos",
            "unit": "Mililitro",
            "category_id": 21,
            "sale_price": 0,
            "purchase_price": 0,
            "reservation": 0,
            "type": 2,
            "description": null,
            "created_at": "2023-02-17 12:21:29",
            "updated_at": "2023-06-20 12:51:02",
            "deleted_at": null,
            "stock": 0,
            "photo": {
              "id": 69,
              "product_id": 90,
              "file": "products/047990eb7301761fd2796a48d9ce04cb.jpg",
              "created_at": "2023-02-17 12:21:29",
              "updated_at": "2023-02-17 12:21:29",
              "deleted_at": null
            },
            "prices": [
              {
                "id": 85,
                "product_id": 90,
                "price": 0,
                "created_at": "2023-05-05 11:17:24",
                "updated_at": "2023-05-05 11:17:24",
                "deleted_at": null
              }
            ],
            "size_colors": [
              {
                "id": 13,
                "product_id": 90,
                "size_id": 3,
                "color_id": 1,
                "sale_price": 60,
                "purchase_price": 10,
                "reservation": 3,
                "created_at": "2023-02-17 12:21:29",
                "updated_at": "2023-02-17 12:22:39",
                "deleted_at": null,
                "stock": 9,
                "prices": []
              },
              {
                "id": 14,
                "product_id": 90,
                "size_id": 6,
                "color_id": 2,
                "sale_price": 70,
                "purchase_price": 15,
                "reservation": 5,
                "created_at": "2023-02-17 12:25:03",
                "updated_at": "2023-02-17 12:25:03",
                "deleted_at": null,
                "stock": 20,
                "prices": []
              }
            ],
            "category": {
              "id": 21,
              "name": "Electrónica",
              "created_at": "2023-02-17 11:52:43",
              "updated_at": "2023-02-17 11:52:43",
              "deleted_at": null
            },
            "brand": {
              "id": 14,
              "name": "HP",
              "created_at": "2023-02-17 11:53:19",
              "updated_at": "2023-02-17 11:53:19",
              "deleted_at": null
            },
            "model": {
              "id": 9,
              "name": "Inalámbrico",
              "brand_id": 14,
              "created_at": "2023-02-17 12:20:50",
              "updated_at": "2023-02-17 12:20:50",
              "deleted_at": null
            }
          }
        ]
      }
    },
    {
      title: 'Productos',
      url: '/api/admin/usersaccess/products/create',
      method: 'POST',
      description: 'Registra un producto de tipo normal',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'type', type: 'number', isRequired: true, description: 'Tipo de producto: [1: normal, 2: talla y color].' },
        { key: 'brand_id', type: 'number', isRequired: false, description: 'ID de marca del producto (obtener en /api/admin/usersaccess/brands).' },
        { key: 'model_id', type: 'number', isRequired: false, description: 'ID de modelo del producto (obtener en /api/admin/usersaccess/models).' },
        { key: 'category_id', type: 'number', isRequired: true, description: 'ID de categoría del producto (obtener en /api/admin/usersaccess/categories).' },
        { key: 'code', type: 'string', isRequired: true, description: 'Código del producto.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre del producto.' },
        { key: 'unit', type: 'string', isRequired: false, description: 'Unidad del producto.' },
        { key: 'sale_price', type: 'number', isRequired: false, description: 'Precio de venta. Pasar si el type es 1 (producto normal)' },
        { key: 'purchase_price', type: 'number', isRequired: false, description: 'Precio de compra. Pasar si el type es 1 (producto normal)' },
        { key: 'quantity', type: 'number', isRequired: false, description: 'Cantidad de stock del producto. Pasar si el type es 1 (producto normal)' },
        { key: 'reservation', type: 'number', isRequired: false, description: 'Cantidad de reserva. Pasar si el type es 1 (producto normal)' },
        { key: 'description', type: 'string', isRequired: false, description: 'Descripcción / comentarios adicionales.' },
        { key: 'prices', type: 'object', isRequired: false, description: 'Precios adicionales. Pasar si el type es 1 (producto normal)' },
        { key: 'items', type: 'object', isRequired: false, description: 'Items del producto si es por talla/color. Pasar si el type es 2 (producto por talla/color)' },
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
        "brand_id": "16",
        "model_id": "13",
        "code": "07778999",
        "name": "nuevo producto",
        "unit": "Pieza",
        "category_id": "23",
        //"sale_price": "8",
        //"purchase_price": "5",
        //"quantity": "10",
        //"reservation": "10",
        "description": "comentarios extras",
        // "prices": [
        //   { "price": 10 },
        //   { "price": 15 }
        // ],
        "type": 2,
        "items": [
          {
            "quantity": "10",
            "size_id": 24,
            "color_id": 23,
            "sale_price": "10"
          },
          {
            "quantity": "10",
            "size_id": 23,
            "color_id": 25,
            "sale_price": "10"
          }
        ]
      },
      exampleResponse: {
        "result": true,
        "product": {
          "id": 205,
          "brand_id": 22,
          "model_id": 21,
          "code": "07778999xs123",
          "name": "nuevo producto talla color from apitoken",
          "unit": "Pieza",
          "category_id": 34,
          "sale_price": 8,
          "purchase_price": 5,
          "reservation": 0,
          "type": 2,
          "description": "comentarios extras",
          "description_store": null,
          "created_at": "2023-11-07 13:07:50",
          "updated_at": "2023-11-07 13:07:50",
          "deleted_at": null,
          "stock": 0,
          "photo": {
            "id": 185,
            "product_id": 205,
            "file": "products/1b6fdd555a9fd04c9a978e61f9fe4fb3.png",
            "created_at": "2023-11-07 13:07:50",
            "updated_at": "2023-11-07 13:07:50",
            "deleted_at": null
          },
          "prices": [],
          "size_colors": [
            {
              "id": 81,
              "product_id": 205,
              "size_id": 24,
              "color_id": 23,
              "sale_price": 10,
              "purchase_price": 0,
              "reservation": 0,
              "created_at": "2023-11-07 13:07:50",
              "updated_at": "2023-11-07 13:07:50",
              "deleted_at": null,
              "stock": 10,
              "prices": []
            },
            {
              "id": 82,
              "product_id": 205,
              "size_id": 23,
              "color_id": 25,
              "sale_price": 10,
              "purchase_price": 0,
              "reservation": 0,
              "created_at": "2023-11-07 13:07:50",
              "updated_at": "2023-11-07 13:07:50",
              "deleted_at": null,
              "stock": 10,
              "prices": []
            }
          ]
        }
      },
    },
    {
      title: 'Productos: Subir Foto',
      url: '/api/admin/usersaccess/products/uploadphoto',
      method: 'POST',
      description: 'Registra hasta 4 imagenes por producto',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'product_id', type: 'number', isRequired: true, description: 'ID del producto' },
        { key: 'files', type: 'object|string', isRequired: true, description: 'Admite imagenes en base64 [string]. Con la extension jpeg o png.' },
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
        "product_id": 148,
        "files": [
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...resto_de_la_cadena_base64...",
          "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAAAAAAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/9sAQwEaAgICAgICAgICAgMCAgMEBAMFBAMEBQYF...resto_de_la_cadena_base64..."
        ]
      },
      exampleResponse: {
        "result": true
      },
    },
    {
      title: 'Ordenes',
      url: '/api/admin/usersaccess/orders',
      method: 'GET',
      description: 'Obtiene sus ordenes/pedidos.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'status', type: 'number', isRequired: false, description: 'Estatus de la orden. [0: pendiente, 1 procesada, 2: rechazada, 5: cancelada].' },
        { key: 'since', type: 'string', isRequired: false, description: 'Fecha "desde" en formato Y-m-d ejemplo: 2023-06-01.' },
        { key: 'until', type: 'string', isRequired: false, description: 'Fecha "hasta" en formato Y-m-d ejemplo: 2023-06-30.' },
        { key: 'nro_order', type: 'number', isRequired: false, description: 'Número de orden [correlative_number].' },
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "orders": [
          {
            "id": 317,
            "correlative_number": 217,
            "client_id": 17,
            "seller_id": 57,
            "method_id": null,
            "credit_days": null,
            "conversion": 25,
            "file": null,
            "subtotal": 250,
            "discount": 0,
            "discount_percentage": 0,
            "total": 250,
            "change": 0,
            "status": 0,
            "status_payment": 1,
            "comments": null,
            "payment_date": null,
            "amount_payment": null,
            "initial_payment": null,
            "commission": 25,
            "commission_manager": 0,
            "information": null,
            "created_at": "2023-06-21 17:06:39",
            "updated_at": "2023-06-21 17:06:39",
            "deleted_at": null,
            "payment_methods_text": "Efectivo $",
            "status_text": "En proceso",
            "number_format": "0217",
            "status_payment_text": "Pagado",
            "to_pay": 250,
            "product_discount": 0,
            "created_es": "21-06-2023",
            "partial_payment": 0,
            "payment_methods": [
              {
                "id": 302,
                "order_id": 317,
                "amount": 250,
                "method_id": 7,
                "created_at": "2023-06-21 17:06:39",
                "updated_at": "2023-06-21 17:06:39",
                "deleted_at": null
              }
            ],
            "paydays": [],
            "details": [
              {
                "id": 530,
                "order_id": 317,
                "product_id": 92,
                "price": 250,
                "quantity": 1,
                "subtotal": 250,
                "discount": 0,
                "discount_percentage": 0,
                "total": 250,
                "color_id": null,
                "size_id": null,
                "created_at": "2023-06-21 17:06:39",
                "updated_at": "2023-06-21 17:06:39",
                "deleted_at": null,
                "product": {
                  "id": 92,
                  "brand_id": 14,
                  "model_id": 8,
                  "code": "7445231",
                  "name": "Audífonos Premium1",
                  "unit": "Pieza",
                  "category_id": 13,
                  "sale_price": 250,
                  "purchase_price": 11,
                  "reservation": 1,
                  "type": 1,
                  "description": "Testing stock 1",
                  "created_at": "2023-02-22 14:46:47",
                  "updated_at": "2023-06-21 17:06:39",
                  "deleted_at": null,
                  "stock": 741,
                  "photo": {
                    "id": 71,
                    "product_id": 92,
                    "file": "products/8f1b3c5cb93805c877d7c6e54dafbd10.jpg",
                    "created_at": "2023-02-22 14:46:47",
                    "updated_at": "2023-02-22 14:46:47",
                    "deleted_at": null
                  },
                  "prices": [],
                  "size_colors": []
                }
              }
            ],
            "seller": {
              "id": 57,
              "name": "nuevo sell",
              "email": "sell2@gmail.com",
              "level_id": 3,
              "phone": "424123456",
              "comments": null,
              "document": "11360195",
              "zone_id": null,
              "state_id": null,
              "commission": 10,
              "address": null,
              "logo": null,
              "google": 0,
              "google_id": null,
              "trial_expiration": "2023-04-23",
              "status_seller": 1,
              "status": 1,
              "verified": 1,
              "remember_token": null,
              "logged_in": 0,
              "sell_note": null,
              "created_at": "2023-02-07 16:10:34",
              "updated_at": "2023-03-24 11:23:36",
              "deleted_at": null,
              "deleted_ios": null,
              "number_format": "0057",
              "status_text": "Activo",
              "level": {
                "id": 3,
                "name": "Vendedor",
                "code": "user/seller",
                "created_at": "2021-04-14 09:58:47",
                "updated_at": "2021-04-14 09:58:47",
                "deleted_at": null
              },
              "zone": null,
              "goal": {
                "id": 12,
                "user_id": 57,
                "amount": 10,
                "date": "2023-03-05",
                "created_at": "2023-02-07 16:10:34",
                "updated_at": "2023-02-07 16:10:34",
                "deleted_at": null
              },
              "permissions": [],
              "zones": [],
              "admin_functionalities": [],
              "admin": {
                "id": 1,
                "name": "Admin",
                "email": "admin@mail.com",
                "level_id": 1,
                "phone": "123456789",
                "comments": null,
                "document": "2342342343",
                "zone_id": null,
                "state_id": null,
                "commission": 10,
                "address": "Av. Principal, Edificio Los Pinos, Piso 5, Apto 503, Caracas",
                "logo": "users/a828f92fe9f729efef094392e0ffe8d6.jpg",
                "google": 0,
                "google_id": null,
                "trial_expiration": "2023-04-23",
                "status_seller": 1,
                "status": 1,
                "verified": 1,
                "remember_token": "1",
                "logged_in": 1,
                "sell_note": null,
                "created_at": "2021-02-10 18:43:17",
                "updated_at": "2023-06-22 11:53:01",
                "deleted_at": null,
                "deleted_ios": null,
                "number_format": "0001",
                "status_text": "Activo",
                "level": {
                  "id": 1,
                  "name": "Admin",
                  "code": "user/admin",
                  "created_at": "2021-04-14 09:58:47",
                  "updated_at": "2021-04-14 09:58:47",
                  "deleted_at": null
                },
                "zone": null,
                "goal": null,
                "permissions": [],
                "zones": [],
                "admin_functionalities": [],
                "admin": null,
                "modules": []
              },
              "modules": []
            },
            "client": {
              "id": 17,
              "seller_id": null,
              "name": "new client",
              "phone": "4243105519",
              "email": "new@mail.com",
              "address": "test",
              "comments": null,
              "document": "27049202",
              "zone_id": 7,
              "state_id": null,
              "latitude": null,
              "longitude": null,
              "status": 1,
              "created_at": "2023-02-03 09:34:59",
              "updated_at": "2023-02-08 17:08:53",
              "deleted_at": null,
              "seller": null
            },
            "method": null
          }
        ]
      }
    },
    {
      title: 'Ordenes',
      url: '/api/admin/usersaccess/orders/create',
      method: 'POST',
      description: 'Registra una orden/pedido',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'seller_id', type: 'number', isRequired: true, description: 'ID del vendedor.' },
        { key: 'client_id', type: 'number', isRequired: true, description: 'ID del cliente.' },
        { key: 'status_payment', type: 'number', isRequired: true, description: 'Tipo de pago: [1: Pagado, 2: Por pagar].' },
        { key: 'details', type: 'object', isRequired: true, description: 'Detalles del pedido.' },
        { key: 'methods_selected', type: 'object', isRequired: true, description: 'Métodos de pago del pedido.' },
      ],
      exampleBody: {
        "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjk2LCJpc3MiOiJodHRwczovL3BocDcudGVhbWxpbW9uYnl0ZS5jb20vU3lzZ2UyNC9hcGkvcHVibGljL2FwaS9hZG1pbi91c2Vyc2FjY2Vzcy9jcmVhdGUiLCJpYXQiOjE2OTkzODA3OTksImV4cCI6MTY5OTM4NDM5OSwibmJmIjoxNjk5MzgwNzk5LCJqdGkiOiJ2MU4zMndlcHFDcUZWeW8zIn0.c5ogkoPNEkTa4G1asCOJpEIlDiM9nwNxkPE70Yzocmc",
        "seller_id": 115,
        "client_id": 21,
        "status_payment": 1,
        "details": [
          {
            "price": 143.75,
            "product_id": 149,
            "quantity": 1,
            "selected_price": 143.75,
            "warehouse_id": 42
          }
        ],
        "methods_selected": [
          {
            "id": 7,
            "amount": "5"
          }
        ]
      },
      exampleResponse: {
        "result": true,
        "message": "Orden creada con exito."
      },
    },
    {
      title: 'Ordenes: Actualizar status',
      url: '/api/admin/usersaccess/orders/updatestatus',
      method: 'POST',
      description: 'Actualiza el status de una orden.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'order_id', type: 'number', isRequired: true, description: 'ID de la orden.' },
        { key: 'type', type: 'number', isRequired: true, description: 'Tipo de operación [1: Estatus general del pedido, 2: Estatus de pago del pedido]' },
        { key: 'status', type: 'number', isRequired: true, description: 'General: [1: En proceso, 2: Rechazado, 5: Anulado/Cancelado, 6: Despachado]\n De Pago: [1: Pagado, 2: Por pagar]' },
        { key: 'comments', type: 'string', isRequired: false, description: 'Comentarios adicionales.' },
      ],
      exampleBody: {
        "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjk2LCJpc3MiOiJodHRwczovL3BocDcudGVhbWxpbW9uYnl0ZS5jb20vU3lzZ2UyNC9hcGkvcHVibGljL2FwaS9hZG1pbi91c2Vyc2FjY2Vzcy9jcmVhdGUiLCJpYXQiOjE2OTkzODA3OTksImV4cCI6MTY5OTM4NDM5OSwibmJmIjoxNjk5MzgwNzk5LCJqdGkiOiJ2MU4zMndlcHFDcUZWeW8zIn0.c5ogkoPNEkTa4G1asCOJpEIlDiM9nwNxkPE70Yzocmc",
        "order_id": 115,
        "type": 1,
        "status": 2,
        "comments": "Rechazando pedido desde el API",
      },
      exampleResponse: {
        "result": true,
        "orders": [
          {
            "id": 115,
            "correlative_number": 217,
            "client_id": 17,
            "seller_id": 57,
            "method_id": null,
            "credit_days": null,
            "conversion": 25,
            "file": null,
            "subtotal": 250,
            "discount": 0,
            "discount_percentage": 0,
            "total": 250,
            "change": 0,
            "status": 2,
            "status_payment": 2,
            "comments": null,
            "payment_date": null,
            "amount_payment": null,
            "initial_payment": null,
            "commission": 25,
            "commission_manager": 0,
            "information": null,
            "created_at": "2023-06-21 17:06:39",
            "updated_at": "2023-06-21 17:06:39",
            "deleted_at": null,
            "payment_methods_text": "Efectivo $",
            "status_text": "Rechazado",
            "number_format": "0217",
            "status_payment_text": "Por pagar",
            "to_pay": 250,
            "product_discount": 0,
            "created_es": "21-06-2023",
            "partial_payment": 0,
            "details": [
              {
                "id": 530,
                "order_id": 115,
                "product_id": 92,
                "price": 250,
                "quantity": 1,
                "subtotal": 250,
                "discount": 0,
                "discount_percentage": 0,
                "total": 250,
                "color_id": null,
                "size_id": null,
                "created_at": "2023-06-21 17:06:39",
                "updated_at": "2023-06-21 17:06:39",
                "deleted_at": null,
                "product": {
                  "id": 92,
                  "brand_id": 14,
                  "model_id": 8,
                  "code": "7445231",
                  "name": "Audífonos Premium1",
                  "unit": "Pieza",
                  "category_id": 13,
                  "sale_price": 250,
                  "purchase_price": 11,
                  "reservation": 1,
                  "type": 1,
                  "description": "Testing stock 1",
                  "created_at": "2023-02-22 14:46:47",
                  "updated_at": "2023-06-21 17:06:39",
                  "deleted_at": null,
                  "stock": 741,
                  "photo": {
                    "id": 71,
                    "product_id": 92,
                    "file": "products/8f1b3c5cb93805c877d7c6e54dafbd10.jpg",
                    "created_at": "2023-02-22 14:46:47",
                    "updated_at": "2023-02-22 14:46:47",
                    "deleted_at": null
                  },
                  "prices": [],
                  "size_colors": []
                }
              }
            ],
          }
        ]
      },
    },
    {
      title: 'Zonas',
      url: '/api/admin/usersaccess/zones',
      method: 'GET',
      description: 'Obtiene los registros de sus zonas.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre de la zona.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "zones": [
          {
            "id": 14,
            "name": "Andes",
            "created_at": "2023-03-20 14:26:49",
            "updated_at": "2023-03-20 14:26:49",
            "deleted_at": null
          },
          {
            "id": 9,
            "name": "Capital",
            "created_at": "2023-02-28 10:52:12",
            "updated_at": "2023-02-28 10:52:12",
            "deleted_at": null
          },
          {
            "id": 8,
            "name": "Centro",
            "created_at": "2023-02-27 10:16:07",
            "updated_at": "2023-02-27 10:16:07",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Zonas',
      url: '/api/admin/usersaccess/zones/create',
      method: 'POST',
      description: 'Registra una Zona',
      exampleBody: {
        "token": "<aqui_su_token>",
        "name": "Valencia",
      },
      exampleResponse: {
        "result": true,
        "zone": {
          "name": "Valencia",
          "updated_at": "2023-11-07 11:36:49",
          "created_at": "2023-11-07 11:36:49",
          "id": 31,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre de la zona.' },
      ],
    },
    {
      title: 'Categorías',
      url: '/api/admin/usersaccess/categories',
      method: 'GET',
      description: 'Obtiene los registros de sus categorías.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre de la categoría.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "categories": [
          {
            "id": 22,
            "name": "Computación",
            "created_at": "2023-02-27 10:04:27",
            "updated_at": "2023-02-27 10:04:27",
            "deleted_at": null
          },
          {
            "id": 23,
            "name": "Accesorios",
            "created_at": "2023-02-28 09:31:22",
            "updated_at": "2023-02-28 09:31:22",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Categorías',
      url: '/api/admin/usersaccess/categories/create',
      method: 'POST',
      description: 'Registra una Categoría',
      exampleBody: {
        "token": "<aqui_su_token>",
        "name": "Nueva category",
      },
      exampleResponse: {
        "result": true,
        "category": {
            "name": "Nueva category",
            "updated_at": "2023-11-13 09:35:52",
            "created_at": "2023-11-13 09:35:52",
            "id": 46,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre de la categoría.' },
      ],
    },
    {
      title: 'Marcas',
      url: '/api/admin/usersaccess/brands',
      method: 'GET',
      description: 'Obtiene los registros de sus marcas.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre de la marca.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "brands": [
          {
            "id": 15,
            "name": "Acme",
            "created_at": "2023-02-27 10:04:51",
            "updated_at": "2023-02-27 10:04:51",
            "deleted_at": null
          },
          {
            "id": 16,
            "name": "HP",
            "created_at": "2023-02-27 16:13:13",
            "updated_at": "2023-02-27 16:13:13",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Marcas',
      url: '/api/admin/usersaccess/brands/create',
      method: 'POST',
      description: 'Registra una Marca',
      exampleBody: {
        "token": "<aqui_su_token>",
        "name": "Nueva marca",
      },
      exampleResponse: {
        "result": true,
        "brand": {
            "name": "Nueva marca",
            "updated_at": "2023-11-13 09:44:06",
            "created_at": "2023-11-13 09:44:06",
            "id": 29,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre de la marca.' },
      ],
    },
    {
      title: 'Modelos',
      url: '/api/admin/usersaccess/models',
      method: 'GET',
      description: 'Obtiene los registros de sus modelos.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'brand_id', type: 'number', isRequired: false, description: 'ID de marca, obtener del api (/api/admin/usersaccess/brands).' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre del modelo.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "models": [
          {
            "id": 11,
            "name": "Note Premium",
            "brand_id": 15,
            "created_at": "2023-02-27 10:05:09",
            "updated_at": "2023-02-27 10:05:09",
            "deleted_at": null
          },
          {
            "id": 12,
            "name": "Mate",
            "brand_id": 15,
            "created_at": "2023-02-27 16:12:24",
            "updated_at": "2023-02-27 16:12:24",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Modelos',
      url: '/api/admin/usersaccess/models/create',
      method: 'POST',
      description: 'Registra un Modelo',
      exampleBody: {
        "token": "<aqui_su_token>",
        "brand_id": 22,
        "name": "Nuevo modelo",
      },
      exampleResponse: {
        "result": true,
        "model": {
          "brand_id": 22,
          "name": "Nuevo modelo",
          "updated_at": "2023-11-13 09:56:20",
          "created_at": "2023-11-13 09:56:20",
          "id": 31,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'brand_id', type: 'number', isRequired: true, description: 'ID de marca, obtener del api (/api/admin/usersaccess/brands).' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre del modelo.' },
      ],
    },
    {
      title: 'Cuentas por Cobrar',
      url: '/api/admin/usersaccess/accountsreceivable',
      method: 'GET',
      description: 'Obtiene las cuentas por cobrar.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre, DNI/RIF o email del cliente.' },
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "por_cobrar": [
          {
            "cliente": "Masivo 3 Zona",
            "total_orden": "75.00",
            "total_abonado": "0.00",
            "debe": "75.00"
          },
          {
            "cliente": "Antonio Limón",
            "total_orden": "45.00",
            "total_abonado": "15.00",
            "debe": "30.00"
          },
          {
            "cliente": "Cliente Masivo",
            "total_orden": "145.75",
            "total_abonado": "100.00",
            "debe": "45.75"
          }
        ],
        "totales": {
          "total_ordenes": "265.75",
          "total_por_cobrar": "150.75"
        }
      }
    },
    {
      title: 'Tallas',
      url: '/api/admin/usersaccess/sizes',
      method: 'GET',
      description: 'Obtiene las tallas registradas.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre de la talla.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "sizes": [
          {
            "id": 7,
            "name": "S",
            "created_at": "2023-03-01 13:43:33",
            "updated_at": "2023-03-01 13:43:33",
            "deleted_at": null
          },
          {
            "id": 8,
            "name": "XL",
            "created_at": "2023-03-01 13:44:09",
            "updated_at": "2023-03-01 13:44:09",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Tallas',
      url: '/api/admin/usersaccess/sizes/create',
      method: 'POST',
      description: 'Registra una Talla',
      exampleBody: {
        "token": "<aqui_su_token>",
        "name": "XS",
      },
      exampleResponse: {
        "result": true,
        "size": {
            "name": "XS",
            "updated_at": "2023-11-07 12:15:34",
            "created_at": "2023-11-07 12:15:34",
            "id": 24,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre de la talla.' },
      ],
    },
    {
      title: 'Colores',
      url: '/api/admin/usersaccess/colors',
      method: 'GET',
      description: 'Obtiene los colores registrados.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre o códido hexadecimal del color.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "colors": [
          {
            "id": 16,
            "name": "negro",
            "hex": "#000000",
            "created_at": "2023-03-08 14:43:50",
            "updated_at": "2023-03-08 14:44:50",
            "deleted_at": null
          },
          {
            "id": 18,
            "name": "Rojo",
            "hex": "#e11919",
            "created_at": "2023-03-08 16:28:53",
            "updated_at": "2023-03-08 16:28:53",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Colores',
      url: '/api/admin/usersaccess/colors/create',
      method: 'POST',
      description: 'Registra un Color',
      exampleBody: {
        "token": "<aqui_su_token>",
        "name": "Verde agua",
        "hex": "#008f8c",
      },
      exampleResponse: {
        "result": true,
        "color": {
            "name": "Verde agua",
            "hex": "#008f8c",
            "updated_at": "2023-11-07 12:18:33",
            "created_at": "2023-11-07 12:18:33",
            "id": 25,
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre del color.' },
        { key: 'hex', type: 'string', isRequired: true, description: 'Código hexadecimal del color.' },
      ],
    },
    {
      title: 'Almacenes',
      url: '/api/admin/usersaccess/warehouses',
      method: 'GET',
      description: 'Obtiene los almacenes registrados.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre del almacén.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>",
      },
      exampleResponse: {
        "result": true,
        "warehouses": [
          {
            "id": 57,
            "name": "Almacen Maracay",
            "created_at": "2023-12-01 12:06:20",
            "updated_at": "2023-12-01 12:06:20",
            "deleted_at": null
          },
          {
            "id": 42,
            "name": "Almacen Principal",
            "created_at": "2023-10-20 13:16:22",
            "updated_at": "2023-10-20 13:16:22",
            "deleted_at": null
          }
        ]
      }
    },
    {
      title: 'Vendedores',
      url: '/api/admin/usersaccess/sellers',
      method: 'GET',
      description: 'Obtiene los registros de sus vendedores.',
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'search', type: 'string', isRequired: false, description: 'Busca por nombre, DNI/RIF o email del Vendedor.' },
        { key: 'created_at', type: 'string', isRequired: false, description: 'Busca por fecha de creación en formato Y-m-d (2024-05-20)'},
        { key: 'updated_at', type: 'string', isRequired: false, description: 'Busca por fecha de actualización en formato Y-m-d (2024-05-20)'},
      ],
      exampleBody: {
        "token": "<aqui_su_token>"
      },
      exampleResponse: {
        "result": true,
        "sellers": [
          {
            "id": 172,
            "name": "Supermercado El Jireth c.a.",
            "email": "J0000000@chrystal.com",
            "level_id": 3,
            "phone": null,
            "comments": null,
            "document": "J0000000",
            "zone_id": null,
            "country_id": 2,
            "state_id": null,
            "commission": 10,
            "calculate_commission": 1,
            "address": null,
            "logo": null,
            "google": 0,
            "google_id": null,
            "facebook": null,
            "instagram": null,
            "twitter": null,
            "trial_expiration": "2024-06-15",
            "status_seller": 1,
            "status": 1,
            "superadmin_block": 0,
            "verified": 0,
            "remember_token": null,
            "logged_in": 0,
            "sell_note": null,
            "show_order_total": 1,
            "can_add_ar_payments": 1,
            "warehouse_id": null,
            "seller_code": null,
            "synchronizer": 0,
            "accept_order_before": 0,
            "created_at": "2024-05-31 11:26:02",
            "updated_at": "2024-05-31 11:26:02",
            "deleted_at": null,
            "deleted_ios": null,
            "number_format": "0172",
            "crypt": "eyJpdiI6IlVDbzNZY0xuYXFTdzNwRjZQbkRjQmc9PSIsInZhbHVlIjoicnFsMWQ1NEUyMFdzaXpyUTRBY09HZz09IiwibWFjIjoiZThhZmMxNTM0ZTQ2ZTk1OGQ1YmVjMmJmZGQ3ZDBkY2FlYmRlMWI4MDQ4MGI0MjMzODJiNzAyZGEzOGFlOTk1NyJ9",
            "status_text": "Activo",
            "last_session": null,
            "warehouse_principal": 42,
            "level": {
              "id": 3,
              "name": "Vendedor",
              "code": "user/seller",
              "created_at": "2021-04-14 09:58:47",
              "updated_at": "2021-04-14 09:58:47",
              "deleted_at": null
            },
            "zone": null,
            "goal": null,
            "permissions": [],
            "zones": [],
            "admin_functionalities": [],
            "modules": [],
            "country": {
              "id": 2,
              "name": "Venezuela",
              "currency_name": "Bolívar",
              "currency_name_plural": "Bolívares",
              "currency_code": "VED",
              "currency_symbol": "Bs.",
              "currency_format": "es-VE",
              "order": 1,
              "status": 1,
              "created_at": "2024-02-21 10:34:12",
              "updated_at": "2024-02-21 10:34:12",
              "deleted_at": null
            },
            "printer_type": null
          }
        ]
      }
    },
    {
      title: 'Vendedores',
      url: '/api/admin/usersaccess/sellers/create',
      method: 'POST',
      description: 'Registra un vendedor',
      exampleBody: {
        "token": "<aqui_su_token>",
        "name": "Nuevo Vendedor",
        "document": "J75984689",
        "email": "newseller2024@gmail.com",
        "phone": "0414000000",
        "password": "123456",
      },
      exampleResponse: {
        "result": true,
        "seller": {
          "name": "Nuevo Vendedor",
          "document": "J75984689",
          "email": "newseller2024@gmail.com",
          "phone": "0414000000",
          "level_id": 3,
          "updated_at": "2024-06-04 12:33:07",
          "created_at": "2024-06-04 12:33:07",
          "id": 174,
          "trial_expiration": {
            "date": "2024-06-19 12:33:07.633439",
            "timezone_type": 3,
            "timezone": "America/Curacao"
          },
          "show_order_total": 1,
          "facebook": null,
          "instagram": null,
          "twitter": null,
          "warehouse_id": null,
          "country_id": 2,
          "number_format": "0174",
          "crypt": "eyJpdiI6IkJSNHVwZDhCcUV6clpKakRDTEZ5T3c9PSIsInZhbHVlIjoiK1krYWU5UnlWK1NRa0I3ZzRcL1BoUVE9PSIsIm1hYyI6IjU3MmVjNmIyM2E3ZDU3OGVmNjM5Njg4MDBlNTIyYzdmNWYxOGEwYzA2OGZlMTBmMGI0NjdjYzBjZTRmYmViNDMifQ==",
          "status_text": "Inactivo",
          "last_session": null,
          "warehouse_principal": 42
        }
      },
      data_explain: [
        { key: 'token', type: 'string', isRequired: true, description: 'Token generado por el admin.' },
        { key: 'name', type: 'string', isRequired: true, description: 'Nombre del vendedor.' },
        { key: 'document', type: 'string', isRequired: true, description: 'Identificación del vendedor. Formato de ejemplo: (V23456719 / J10789360).' },
        { key: 'email', type: 'string', isRequired: true, description: 'Email del vendedor, debe ser único por vendedor.' },
        { key: 'phone', type: 'string', isRequired: false, description: 'Teléfono del vendedor.' },
        { key: 'password', type: 'string', isRequired: true, description: 'Contraseña.' },
      ],
    },
  ];

  const tokenResponses = {
    '200': { 'result': true },
    '403': { 'result': false, 'error': 'No tiene acceso a esta api.' },
  };

  const AddTokenButton = () => (
    <Button
      title="Nueva API Token"
      small="true"
      onClick={() => generateToken()}
    >
      <img src={AddIcon} alt="Nuevo Token" />
    </Button>
  );

  return (
    <div id="api-token">
      <div className="row">
        <div className="col-3">
          <p className="label clickable" onClick={() => setTab(TABS.ONBOARDING)}>
            Introducción
          </p>

          <p className="label clickable" onClick={() => setTab(TABS.TOKEN)}>
            Solicitar Token
          </p>

          <p className="label mb-0">Servicios de Consultas</p>
          <ul>
            {endpoints.filter(e => e.method === 'GET').map(endpoint => (
              <li className="clickable" onClick={() => showEndpointInfo(endpoint)}>
                {endpoint.title}
              </li>
            ))}
          </ul>

          <p className="label mb-0">Servicios de Escritura</p>
          <ul>
            {endpoints.filter(e => e.method === 'POST').map(endpoint => (
              <li className="clickable" onClick={() => showEndpointInfo(endpoint)}>
                {endpoint.title}
              </li>
            ))}
          </ul>
        </div>

        <div className="col-9 right-content">
          {tab === TABS.ONBOARDING && (
            <Card>
              <Card.Body>
                <Card.Title>Introducción</Card.Title>
                <Card.Text>
                  <p>Nuestra plataforma ofrece una completa guía de Application Programming Interfaces (API), la cual te permitirá interactuar directamente con todos los datos necesarios para integrar con Sysge24.</p>
                  <p>Las APIs que proporcionamos siguen el estándar REST y están diseñadas para aceptar solicitudes tipo JSON, así mismo devuelven respuestas también en formato JSON. Además, hacen uso de los códigos de respuesta, métodos de autenticación y peticiones estándar de HTTP.</p>
                  <p>Para interactuar con estas APIs, será necesario generar un token. Generar este token es muy fácil, solo debes acceder al módulo <span className="link clickable" onClick={() => setTab(TABS.TOKEN)}>Solicitar Token</span> y generar tu token, la llave te llegará al correo electrónico del administrador.</p>
                  <p>URL Base de cada entorno <b>https://api.sysge24.com</b></p>
                </Card.Text>
              </Card.Body>
            </Card>
          )}

          {tab === TABS.TOKEN && (
            <Card>
              <Card.Body>
                <Card.Title>Solicitar Token</Card.Title>
                <Card.Text>
                  <p>Para crear un nuevo token, puede solicitarlo presionando el botón crear, esta acción creará un nuevo token y lo enviará al correo electrónico del Administrador principal de la cuenta.</p>
                  <p>El token creado no posee tiempo de expiración. Por ahora solo se puede crear un solo token, si deseas crear uno nuevo debes eliminarlo y repetir el proceso, esto enviará un nuevo token al correo.</p>
                </Card.Text>

                <Table
                  data={tokens.length}
                  title="API Tokens"
                  header={['Nombre', 'Fecha de creación', 'Acciones']}
                  message_api_token={true}
                  right={!tokens.length ? <AddTokenButton /> : undefined}
                >
                  {tokens.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td>Token de acceso a API</td>
                        <td>{Item.date_es}</td>
                        <td style={{ maxWidth: 'unset' }}>
                          <Button
                            color="red"
                            title="Eliminar"
                            small="true"
                            onClick={() => deleteToken(Item, index)}
                          >
                            <Icon name="trash" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>

                <div className="code-block">
                  <div className="code-block-header">
                    <div className="title">Ejemplo de respuestas</div>
                  </div>
                  <div className="options">
                    <div
                      className={`success ${viewTokenResp === 200 ? 'selected' : ''}`}
                      onClick={() => setViewTokenResp(200)}
                    >
                      200
                    </div>
                    <div
                      className={`danger ${viewTokenResp === 403 ? 'selected' : ''}`}
                      onClick={() => setViewTokenResp(403)}
                    >
                      403
                    </div>
                  </div>
                  <p className="code">
                    <pre>
                      <code>
                        {JSON.stringify(tokenResponses[viewTokenResp], null, 3)}
                      </code>
                    </pre>
                  </p>
                </div>
              </Card.Body>
            </Card>
          )}

          {tab === TABS.ENDPOINT && (
            <Card>
              <Card.Body>
                <Card.Title>{selectedEndpoint.title}</Card.Title>
                <Card.Text>{selectedEndpoint.description}</Card.Text>

                <div className="code-block">
                  <div className="code-block-header">
                    <div className="title">{selectedEndpoint.title}</div>
                    <div
                      className="action"
                      onClick={() => {
                        Globals.copyToClipboard(ENV.BasePublic + String(selectedEndpoint.url).substring(1))
                      }}
                    >
                      <i className="fa fa-copy" />
                    </div>
                  </div>
                  <p className="code">
                    {selectedEndpoint.method} {selectedEndpoint.url}
                  </p>
                </div>

                {!!selectedEndpoint?.data_explain.length && (
                  <>
                    <Card.Subtitle className="mb-2 text-muted">Parametros:</Card.Subtitle>
                    <div className="params">
                      {selectedEndpoint?.data_explain?.map(prop => (
                        <div className="params-item">
                          <span className="params-key">
                            {prop.key}
                          </span>
                          <span className="params-type">
                            {prop.type}
                          </span>
                          {prop.isRequired && (
                            <span className="params-required">requerido</span>
                          )}
                          <span className="params-description">
                            {prop.description}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <Card.Subtitle className="mb-2 text-muted">Ejemplo de uso:</Card.Subtitle>
                <Card.Text className="json-response">Body</Card.Text>
                <p className="code">
                  <pre>
                    <code>
                      {JSON.stringify(selectedEndpoint.exampleBody, null, 3)}
                    </code>
                  </pre>
                </p>

                <Card.Text className="json-response">Response</Card.Text>
                <p className="code">
                  <pre>
                    <code>
                      {JSON.stringify(selectedEndpoint.exampleResponse, null, 3)}
                    </code>
                  </pre>
                </p>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

const useFetch = (userId) => {
  const [user_id] = useState(userId);
  const [tokens, setTokens] = useState([]);

  const getToken = () => {
    Globals.setLoading();

    axios
      .post("admin/usersaccess/get", { user_id })
      .then(res => {
        if (res.data.result) {
          setTokens(res.data.token || []);
        }
      })
      .catch(() => {
        Globals.showError();
      })
      .finally(() => {
        Globals.quitLoading();
      });
  }

  const generateToken = () => {
    Globals.setLoading();
    axios
      .post("admin/usersaccess/create", { user_id })
      .then(res => {
        if (res.data.result) {
          getToken();
          Globals.showSuccess("¡Token registrado con exito! revisa tu cuenta de email");
        }
      })
      .catch(() => {
        Globals.showError();
      })
      .finally(() => {
        Globals.quitLoading();
      });
  }

  const deleteToken = ({ id }) => {
    Globals.confirm("¿Seguro de eliminar su token?", () => {
      Globals.setLoading("Eliminando...");
      axios
        .post(`admin/usersaccess/delete`, { id })
        .then(res => {
          Globals.showSuccess('Token eliminado');
          getToken();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .finally(() => {
          Globals.quitLoading();
        });
    });
  }

  useEffect(() => {
    getToken();
  }, []);

  return {
    tokens,
    getToken,
    generateToken,
    deleteToken,
  }
}

export default APIGuide;