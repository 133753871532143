import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import { Table, Pagination, Button, Select, Icon, DatePicker, Input, Modal } from "../../components";
import { axios, Globals, ENV } from "../../utils";
import PrinterIcon from "../../assets/icons/printer.png";

const INITIAL_FORM = {
  since: "",
  until: "",
  search: "",
  status: "",
  warehouse_id: "",
  seller_id: "",
}

class ReportAutosale extends React.Component {

  state = {
    header: ['Fecha','Vendedor','Almacén Móvil','Cant. Pedidos Procesados','Total Asignado ($)','Total Vendido ($)','Total Retornado ($)','Acción'],
    page: 1,
    last_page: 1,
    data: [],
    inventory: [],
    form: { ...INITIAL_FORM },
    visible: false,
    item: null,
    sellers: [],
    warehouses: [],
    total_pedidos: 0,
  }

  componentDidMount() {
    this.fetchData();
    this.getSelects();
  }

  fetchData = (page = 1) => {
    let { form } = this.state;

    axios
      .post("admin/auto-sales/report?page=" + page, {
        ...form,
        user_id: this.props.user.id,
        warehouse_id: this.state.form.warehouse_id,
        since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
        until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      })
      .then(res => {
        this.setState({
          page,
          last_page: res.data.auto_sales.last_page,
          data: res.data.auto_sales.data,
          total_pedidos: res.data.total_sold,
        });

        // if (res.data.admin?.admin_functionalities?.map((i) => i.id).indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) === -1) {
        //   this.props.history.replace('/');
        // }
      })
      .catch((e) => {
        console.log(e)
        Globals.showError();
      });
  }

  fetchInventory = (auto_sale_id) => {
    axios
      .post("admin/auto-sales/report/view", {
        user_id: this.props.user.id,
        auto_sale_id,
      })
      .then(res => {
        this.setState({ inventory: res.data });
      })
      .catch((e) => {
        console.log(e)
        Globals.showError();
      });
  }

  getSelects = () => {
    axios
      .post("admin/auto-sales/getselects", { user_id: this.props.user.id })
      .then(({ data }) => {
        const sellers = data.sellers.map(x => ({ ...x, value: x.id, label: x.name }));
        const warehouses = data.warehouses.map(x => ({ ...x, value: x.id, label: x.name }));
        const zones = data.zones.map(x => ({ ...x, value: x.id, label: x.name }));
        this.setState({ sellers, warehouses, zones });
      })
      .catch(() => Globals.showError());
  }

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  }

  cleanFilters = async () => {
    await this.setState({ form: INITIAL_FORM });
    this.fetchData();
  }

  canClearFilters = () => {
    return JSON.stringify(this.state.form) !== JSON.stringify(INITIAL_FORM);
  }

  view = (item) => {
    this.fetchInventory(item.id);
    this.setState({ visible: true, item, inventory: [] });
  }

  delete = (item) => {
    Globals.confirm('¿Estás seguro de eliminar esta autoventa?', () => {
      Globals.setLoading();
      axios
        .post("admin/auto-sales/delete", { user_id: this.props.user.id, auto_sale_id: item.id })
        .then(({ data }) => {
          if (!data.result) throw new Error();
          Globals.showSuccess('Autoventa eliminada');
          this.fetchData();
        })
        .catch(() => Globals.showError())
        .finally(() => Globals.quitLoading());
    });
  }

  close = () => {
    this.setState({ visible: false, item: null });
  }

  print = () => {
    axios
      .post('admin/auto-sales/report/download', {
        ...this.state.form,
        user_id: this.props.user.id,
        web: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Clientes -> download -> catch: ', err);
      });
  }

  render() {
    const { form, item, inventory, visible, total_pedidos, } = this.state;
    console.log(item)

    return (
      <Menu history={this.props.history}>

        {visible && (
          <Modal
            size="lg"
            className="modal-autosale-report-view"
            visible
            onClose={this.close}
          >
            <div className="header">
              <div>
                <p className="header-title">{item?.seller?.name || ''}</p>
                <p className="header-subtitle">{ moment(item?.date).format('DD/MM/YYYY') } - { moment(item?.date_until).format('DD/MM/YYYY') }</p>
              </div>
              <div>
                <p className="header-title">Inventario</p>
                <p className="header-subtitle">{ item?.details[0]?.warehouse?.name || '' }</p>
              </div>
            </div>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Código de Producto</th>
                  <th>Producto</th>
                  <th>Unidad de Medida</th>
                  <th>Cantidad Inicial</th>
                  <th>Cantidad Vendida</th>
                  <th>Cantidad Final</th>
                </tr>
              </thead>
              <tbody>
                {inventory.map(i => (
                  <tr>
                    <td>{ i.product_code }</td>
                    <td>{ i.product_name }</td>
                    <td>{ i.product_unit }</td>
                    <td>{ i.init_quantity }</td>
                    <td>{ i.sold_quantity }</td>
                    <td>{ i.finish_quantity }</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal>
        )}

        <div id="visit-planner">
          <div className="row">
            <div className="col-2">
              <DatePicker
                color="white"
                label="Desde"
                maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
                className="filter"
                labelClass="filter"
                value={this.state.form.since}
                onChange={value => this.change(value, 'since')}
              />
            </div>
            <div className="col-2">
              <DatePicker
                color="white"
                label="Hasta"
                minDate={form.since ? moment(form.since).toDate() : null}
                className="filter"
                value={form.until}
                onChange={value => this.change(value, 'until')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Vendedor"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={this.state.sellers}
                value={form.seller_id}
                onChange={e => this.change(e.target.value, 'seller_id')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Almacén"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={this.state.warehouses}
                value={form.warehouse_id}
                onChange={e => this.change(e.target.value, 'warehouse_id')}
              />
            </div>

            <div className="col-4 row">
              <Button
                className="btn-align-bottom btn-filter mr-3"
                color=" "
                onClick={() => this.fetchData()}
              >
                <span>Filtrar</span>
              </Button>

              {this.canClearFilters() && (
                <Button
                  className="btn-align-bottom btn-filter"
                  color=" "
                  onClick={this.cleanFilters}
                >
                  <span>Limpiar</span>
                </Button>
              )}
            </div>
          </div>

          <div className="row monthly-earnings mb-3">
            <div className="col-sm-2" style={{ backgroundColor: '#BAB3B1', padding: '10px', margin: '5px' }}>
              <span className="" style={{ fontWeight: 'bold' }}>TOTAL PEDIDOS</span>
              <div style={{ fontWeight: 'bold' }}>{Globals.formatMiles(total_pedidos)} $</div>
            </div>
          </div>


          <Table
            title="Reporte de Autoventas"
            data={this.state.data.length}
            header={this.state.header}
            right={
              <button style={{ border: 'none' }} onClick={() => this.print()}>
                <img src={PrinterIcon} width={32} alt="Agregar" />
              </button>
            }
          >
            {this.state.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{ moment(item.date).format('DD-MM-YYYY') } - { moment(item.date_until).format('DD-MM-YYYY') }</td>
                  <td>{ item?.seller?.name }</td>
                  <td>{ item?.details[0]?.warehouse?.name }</td>
                  <td>{ item?.processed_orders_count }</td>
                  <td>{ Globals.formatMiles(item.total_assigned) }</td>
                  <td>{ Globals.formatMiles(item.total_sold) }</td>
                  <td>{ Globals.formatMiles(item.total_return) }</td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => this.view(item)}
                    >
                      <Icon name="eye" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => this.fetchData(page)}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => ({
  user: state.user
}))(ReportAutosale);
