import React from "react";
import { Button, Input, InputFormat, Modal, Select, SelectActions } from "../../components";
import { axios, Globals, Constants, ENV } from "../../utils";
import AddIcon from '../../assets/icons/add.png';
import CreateEditCategory from "../category/create_edit_category";
import CreateEditBrand from "../brands/create_edit_brand";
import CreateEditSize from "../sizes/create_edit_size";
import CreateEditColor from "../colors/create_edit_color";
import CreateEditModel from "../models/create_edit_model";

const initialSizeColorPriceItem = {
  price: '',
}

const initialSizeColorItem = {
  quantity: '',
  size_id: '',
  color_id: '',
  sale_price: '',
  purchase_price: '',
  reservation: '',
  prices: []
};

const initialForm = {
  user_id: '',
  admin_id: '',
  brand_id: '',
  model_id: '',
  code: '',
  name: '',
  unit: '',
  category_id: '',
  sale_price: '',
  purchase_price: '',
  reservation: '',
  type: Constants.PRODUCTS_TYPE.NORMAL,
  description: '',
  description_store: '',
  quantity: '',
  photo: {},
  currentPhoto: null,
  prices: [
    {
      id: "",
      price: "",
      default_price: null,
    }
  ],
  items: [
    { ...initialSizeColorItem },
  ],
  exento: '',
  reference: '',
};

class CreateEditProduct extends React.Component {
  state = {
    modalCategory: false,
    modalBrand: false,
    modalModel: false,
    modalSize: false,
    modalColor: false,
    form: {
      ...initialForm,
      user_id: this.props.user.id,
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      warehouse_id: this.props.warehouse_id,
    },
    colors: [],
    sizes: [],
    categories: this.props.categories ?? [],
    brands: this.props.brands ?? [],
    models: [],
    textButton: "Crear",
    type_ventas: Constants.PRODUCTS_TYPE_SALES,
    type_exento: [
      {value: 0, label: 'NO'},
      {value: 1, label: 'SI'},
    ],
    units: Constants.UNITS,
    user: this.props.user
  };

  componentDidMount() {
    if (this.props.edit) this.edit();
    this.getSizes();
    this.getColors();
    this.getModels();
  }

  submit = async () => {
    const { form } = this.state;

    const param = {
      ...form,
      prices: JSON.stringify(form.prices),
      items: JSON.stringify(form.items),
      web: true,
      warehouse_id: this.props.warehouse_id,
    }

    Globals.setLoading();
    axios
      .upload(
        this.props.edit ? "admin/inventory/edit" : "admin/inventory/create",
        param
      )
      .then(res => {
        if(res.data?.result == false){
          return Globals.showError('Ocurrió un error')
        }
        this.setState({ form: { ...initialForm } });
        let msg = ''
        this.props.edit ? msg = "Producto editado exitosamente" : msg = "Producto creado exitosamente"
        Globals.showSuccess(msg);
        this.props.onClose();
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.error);
          return;
        }
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    const currentPhoto = !!this.props.edit.element.photo?.file
      ? Globals.fromPhotos(this.props.edit.element.photo.file)
      : null;

    const item = this.props.edit.element;
    console.log(item)

    await this.setState({
      form: {
        id: item.id,
        product_id: item.id,
        user_id: this.props.user.id,
        admin_id: item.admin_id,
        brand_id: item.brand_id,
        model_id: item.model_id,
        code: item.code,
        name: item.name,
        unit: item.unit,
        category_id: item.category_id,
        sale_price: item.sale_price,
        purchase_price: item.purchase_price,
        reservation: item.reservation, //item.stock_warehouse !== undefined && item.stock_warehouse !== null ? null : item.reservation,
        type: item.type,
        description: item.description,
        description_store: item.description_store !== null ? item.description_store : '',
        quantity: item.quantity ? item.quantity : (item.stock_warehouse !== undefined && item.stock_warehouse !== null
          ? item.stock_warehouse
          : item.stock),
        photo: {},
        currentPhoto,
        prices: item.prices || [],
        items: item.size_colors
          .filter(sc => item?.warehouse_product?.some(wp => wp.size_color_id === sc.id))
          .map(sc => {
            const warehouse_product = item?.warehouse_product?.find(wp => wp.size_color_id === sc.id);
            if (!warehouse_product) return false;

            sc.quantity = warehouse_product.quantity;
            return sc;
          }) || [],
        exento: item.exento,
        reference: item.reference ?? '',
      },
      textButton: "Editar"
    });
  };

  choosePhoto = (e) => {
    const currentPhoto = URL.createObjectURL(e.target.files[0]);
    this.setState({
      form: {
        creator_id: this.props.user.id,
        brand_id: this.props.brand_id,
        ...this.state.form,
        photo: e.target.files[0],
        currentPhoto: currentPhoto,
      }
    });
  }

  change = e => {
    if (e.target.name === "code") {
      e.target.value = e.target.value.toUpperCase();
    }
    this.setState({
      form: {
        creator_id: this.props.user.id,
        brand_id: this.props.brand_id,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  checkedChange = (newValue) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        exento: newValue == true ? 1 : 0
      }
    }));
  }


  getCategories = () => {
    const { user } = this.props;
    axios
    .post("web/admin/categories?page=", { user_id: user.id })
    .then(async res => {
      if (res.data.result)
        await this.setState({ categories: res.data.categories.data });
    })
    .catch(() => Globals.showError())
    .finally(() => Globals.quitLoading());
  };

  getBrands = () => {
    const { user } = this.props;
    axios.post('web/admin/brands?page=', { user_id: user.id })
			.then(async res => {
				if (res.data)
          await this.setState({ brands: res.data.brands });
			})
			.catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };

  getModels = () => {
    const { user } = this.props;
    axios.post('web/admin/models?page=', { user_id: user.id, web: true })
			.then(async res => {
				if (res?.data?.models)
          await this.setState({ models: res.data.models });
			})
			.catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };

  getSizes = () => {
    const { user } = this.props;
    axios.post('web/admin/sizes?page=', { user_id: user.id, web: true })
			.then(async res => {
				if (res.data)
          await this.setState({ sizes: res.data.sizes });
			})
			.catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };

  getColors = () => {
    const { user } = this.props;
    axios.post('web/admin/colors/get?page=', { user_id: user.id, web: true })
			.then(async res => {
				if (res.data)
          await this.setState({ colors: res.data.colors });
			})
			.catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };


  createSelectItem = (modalName) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({ [modalName]: true, editItem: null });
  };

  editSelectItem = (modalName, item, index) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({
      [modalName]: true,
      editItem: {
        element: item,
        index: index,
      }
    })
  };

  deleteCategory = (item) => {
		Globals.confirm('¿Desea eliminar la categoría: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/categories/delete', { category_id: item.id })
				.then(res => {
					Globals.showSuccess('Categoría eliminada con éxito');
					this.close('category');
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  };

  deleteBrand = (item) => {
		Globals.confirm('¿Desea eliminar la marca: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/brands/delete', { brand_id: item.id })
				.then(res => {
					Globals.showSuccess('Marca eliminada con éxito');
					this.close('brand');
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  };

  deleteModel = (item) => {
		Globals.confirm('¿Desea eliminar el modelo: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/models/delete', { model_id: item.id })
				.then(res => {
					Globals.showSuccess('Modelo eliminado con éxito');
					this.close('model');
				})
				.catch(err => {
					Globals.showError(err?.response?.data?.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  };

  deleteColor = (item) => {
		Globals.confirm('¿Desea eliminar el color: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/colors/delete', { color_id: item.id })
				.then(res => {
					Globals.showSuccess('Color eliminado con éxito');
					this.close('color');
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  };

  deleteSize = (item) => {
		Globals.confirm('¿Desea eliminar la talla: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/sizes/delete', { size_id: item.id })
				.then(res => {
					Globals.showSuccess('Talla eliminada con éxito');
					this.close('size');
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  };

	close = async (reloadItems = false) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({
      modalCategory: false,
      modalBrand: false,
      modalModel: false,
      modalSize: false,
      modalColor: false,
      editItem: null,
    });

		if (!reloadItems) return;

    switch (reloadItems) {
      case 'category': this.getCategories(); break;
      case 'brand': this.getBrands(); break;
      case 'size': this.getSizes(); break;
      case 'color': this.getColors(); break;
      case 'model': this.getModels(); break;

      default:
        break;
    }
	};

  render() {

    const { form, colors, sizes, user } = this.state;
    const models = this.state.models.filter(x => x.brand_id === form.brand_id);
    const change_price = Globals.permissionChangeProductsPrices(user)
    const change_quantity = Globals.permissionChangeProductsQuantity(user)

    return (
      <div className="container-create-edit-user">
        <Modal
          title={`${this.state.editItem ? 'Editar' : 'Nueva'} categoría`}
          onClose={() => this.close()}
          visible={this.state.modalCategory}
        >
          <CreateEditCategory
            user={this.props.user}
            edit={this.state.editItem}
            onClose={() => this.close('category')}
          />
        </Modal>

        <Modal
          title={`${this.state.editItem ? 'Editar' : 'Nueva'} marca`}
          onClose={() => this.close()}
          visible={this.state.modalBrand}
        >
          <CreateEditBrand
            user={this.props.user}
            edit={this.state.editItem}
            onClose={() => this.close('brand')}
          />
        </Modal>

        <Modal
          title={`${this.state.editItem ? 'Editar' : 'Nueva'} modelo`}
          onClose={() => this.close()}
          visible={this.state.modalModel}
        >
          <CreateEditModel
            brand_id={form.brand_id}
            user={this.props.user}
            edit={this.state.editItem}
            onClose={() => this.close('model')}
          />
        </Modal>

        <Modal
          title={`${this.state.editItem ? 'Editar' : 'Nueva'} talla`}
          onClose={() => this.close()}
          visible={this.state.modalSize}
        >
          <CreateEditSize
            user={this.props.user}
            edit={this.state.editItem}
            onClose={() => this.close('size')}
          />
        </Modal>

        <Modal
          title={`${this.state.editItem ? 'Editar' : 'Nuevo'} color`}
          onClose={() => this.close()}
          visible={this.state.modalColor}
        >
          <CreateEditColor
            user={this.props.user}
            edit={this.state.editItem}
            onClose={() => this.close('color')}
          />
        </Modal>

        {this.state.modalZones && (
          <div
            className="backdrop"
            onClick={() => this.close()}
          />
        )}

        <div className="row">
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginBottom: '2rem' }}>
            <div style={{ position: 'relative' }}>
              <div style={{ width: '10rem', height: '10rem', borderRadius: '50%', overflow: 'hidden', border: '2px solid #eee' }}>
                {!!form.currentPhoto && (
                  <img
                    src={form.currentPhoto}
                    alt="Foto del producto"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                )}
                <label title="Agregar foto" htmlFor="photo">
                  <i
                    className="fa fa-camera"
                    style={{
                      position: 'absolute',
                      bottom: '0.25rem',
                      right: '0.25rem',
                      fontSize: '20px',
                      backgroundColor: 'white',
                      padding: '0.5rem',
                      borderRadius: '50%',
                      border: '2px solid #eee',
                      cursor: 'pointer',
                    }}
                  />
                </label>
                <input
                  id="photo"
                  type="file"
                  name="photo"
                  style={{ display: 'none' }}
                  onChange={this.choosePhoto}
                />
              </div>
            </div>
          </div>

          <div className="row mx-4">
            <div className="col-md-6">
              <Input
                color=" "
                value={form.name}
                name="name"
                label="Nombre"
                labelClass="material"
                className="material"
                onChange={this.change}
              />
            </div>
            <div className="col-md-6">
              <Input
                color=" "
                value={form.code}
                name="code"
                label="Código"
                labelClass="material"
                className="material"
                onChange={this.change}
              />
            </div>
            <div className="col-md-6">
              <Input
                color=" "
                value={form.reference}
                name="reference"
                label="Referencia (Opcional)"
                labelClass="material"
                className="material"
                onChange={this.change}
              />
            </div>
            <div className="col-md-6">
              {/* <Input
                color=" "
                value={form.unit}
                name="unit"
                label="Unidad"
                labelClass="material"
                className="material"
                onChange={this.change}
              /> */}
              <Select
                color="white"
                name="unit"
                label="Unidad"
                defaultname="Seleccione"
                labelClass="material"
                className="material"
                onChange={this.change}
                value={form.unit}
                options={this.state.units}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <SelectActions
              label="Categoría"
              placeholder="Seleccione"
              actionOptions={[
                {
                  onClick: () => this.createSelectItem('modalCategory'),
                  content: (
                    <div>
                      <span>Añadir</span>
                      <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                    </div>
                  ),
                }
              ]}
              options={this.state.categories.map((i, idx) => ({
                ...i,
                value: i.id,
                label: i.name,
                actions: [
                  <i
                    key={`categories-${i.value}-edit`}
                    className="fa fa-regular fa-edit"
                    title="Editar"
                    onClick={() => this.editSelectItem('modalCategory', i, idx)}
                  />,
                  <i
                    key={`categories-${i.value}-close`}
                    className="fa fa-regular fa-close"
                    title="Eliminar"
                    onClick={() => this.deleteCategory(i, idx)}
                  />,
                ],
              }))}
              value={form.category_id}
              onChange={v => this.change({ target: { value: v, name: 'category_id' } })}
            />
          </div>
          <div className="col-md-3">
            <SelectActions
              label="Marca"
              placeholder="Seleccione"
              actionOptions={[
                {
                  onClick: () => this.createSelectItem('modalBrand'),
                  content: (
                    <div>
                      <span>Añadir</span>
                      <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                    </div>
                  ),
                }
              ]}
              options={this.state.brands.map((i, idx) => ({
                ...i,
                value: i.id,
                label: i.name,
                actions: [
                  <i
                    key={`brand-${i.value}-edit`}
                    className="fa fa-regular fa-edit"
                    title="Editar"
                    onClick={() => this.editSelectItem('modalBrand', i, idx)}
                  />,
                  <i
                    key={`brand-${i.value}-close`}
                    className="fa fa-regular fa-close"
                    title="Eliminar"
                    onClick={() => this.deleteBrand(i, idx)}
                  />,
                ],
              }))}
              value={form.brand_id}
              onChange={v => this.change({ target: { value: v, name: 'brand_id' } })}
            />
          </div>
          <div className="col-md-3">
            <SelectActions
              label="Modelo"
              placeholder="Seleccione"
              actionOptions={[
                {
                  onClick: () => {
                    if (!form.brand_id)
                      return Globals.showError('Primero debe elegir una marca');
                    this.createSelectItem('modalModel');
                  },
                  content: (
                    <div>
                      <span>Añadir</span>
                      <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                    </div>
                  ),
                }
              ]}
              options={models.map((i, idx) => ({
                ...i,
                value: i.id,
                label: i.name,
                actions: [
                  <i
                    key={`model-${i.value}-edit`}
                    className="fa fa-regular fa-edit"
                    title="Editar"
                    onClick={() => this.editSelectItem('modalModel', i, idx)}
                  />,
                  <i
                    key={`model-${i.value}-close`}
                    className="fa fa-regular fa-close"
                    title="Eliminar"
                    onClick={() => this.deleteModel(i, idx)}
                  />,
                ],
              }))}
              value={form.model_id}
              onChange={v => this.change({ target: { value: v, name: 'model_id' } })}
            />
          </div>
          <div className="col-md-3">
            <Select
              color="white"
              name="type"
              label="Tipo de venta"
              defaultname="Seleccione"
              labelClass="material"
              className="material"
              onChange={this.change}
              value={form.type}
              options={this.state.type_ventas}
            />
          </div>
        </div>

        <div className="row">
          {Number(form.type) === Constants.PRODUCTS_TYPE.NORMAL && (
            <Normal
              form={form}
              onChange={this.change}
              change_price={change_price}
              change_quantity={change_quantity}
              product_id={form.product_id}
            />
          )}

          {Number(form.type) === Constants.PRODUCTS_TYPE.SIZE_COLOR && (
            <SizeColor
              items={form.items}
              updateItems={v => this.change({ target: { name: 'items', value: v } })}
              createSelectItem={this.createSelectItem}
              editSelectItem={this.editSelectItem}
              sizes={sizes}
              colors={colors}
              deleteSize={this.deleteSize}
              deleteColor={this.deleteColor}
              change_price={change_price}
              change_quantity={change_quantity}
              product_id={form.product_id}
            />
          )}

          <div className="col-md-12 mt-3">
            <div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '8rem' }}>
              <input
                name="exento"
                id="exento-check"
                type="checkbox"
                checked={form.exento === 1}
                style={{ marginRight: '0.5rem' }}
                onChange={() => this.checkedChange(!form.exento)}
              />
              <label className="" htmlFor="exento-check" style={{ fontSize: 20, marginBottom: 0 }}>
                Exento de IVA
              </label>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <Input
              value={form.description_store}
              name="description_store"
              label="Descripción del producto (Opcional)"
              labelClass="material"
              className="material"
              onChange={this.change}
            />
          </div>

          <div className="col-md-12 mt-3">
            <Input
              value={form.description}
              name="description"
              label="Agregar información adicional del producto (Opcional)"
              labelClass="material"
              className="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div id="button" className="d-flex justify-content-center">
          <Button
            color=" "
            type="button"
            className="primary"
            onClick={() => this.submit()}
          >
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}

const Normal = ({ form, onChange, change_price, change_quantity, product_id }) => {

  const addPrice = () => {
    const newItems = [ ...form.prices ];
    newItems.push({ ...initialSizeColorPriceItem });
    onChange({ target: { name: 'prices', value: newItems } });
  }

  const deletePrice = (priceIdx) => {
    const newItems = form.prices.filter((_, i) => i !== priceIdx);
    onChange({ target: { name: 'prices', value: newItems } });
  }

  const changePrice = (priceIdx, value) => {
    const newItems = [ ...form.prices ];
    newItems[priceIdx].price = Globals.limitIntLength(value);
    onChange({ target: { name: 'prices', value: newItems } });
  }

  const changeDefaultPrice = (priceIdx, value) => {
    console.log(value)
    const newItems = [ ...form.prices ];

    // Verificar si el valor es 1 y si ya existe otro precio con default_price en 1
    if (value === 1 && newItems.some(price => price.default_price === 1)) {
      Globals.showError('Solo puedes seleccionar un precio para mostrar en la web')
      return; // Detener la ejecución de la función
    }

    newItems[priceIdx].default_price = value;
    onChange({ target: { name: 'prices', value: newItems } });
  }

  return (
    <>
      <div className="col-md-6 col-lg-3">
        <Input
          type="number"
          value={form.quantity}
          name="quantity"
          label="Cantidad"
          labelClass="material"
          className="material"
          onChange={onChange}
          disabled={change_quantity}
        />
      </div>
      <div className="col-md-6 col-lg-3">
        <Input
          type="number"
          value={form.reservation}
          name="reservation"
          label="Reserva"
          labelClass="material"
          className="material"
          onChange={onChange}
          disabled={change_quantity}
        />
      </div>
      {product_id !== undefined && (
        <div className="col-md-6 col-lg-3">
          <InputFormat
            name="purchase_price"
            maxLength={11}
            decimalScale={2}
            thousandSeparator={false}
            allowNegative={false}
            isNumericString={true}
            placeholder="0.00"
            label="Precio de compra"
            labelClass="material"
            customClass="material"
            value={form.purchase_price}
            onChange={e => onChange({
              target: {
                name: e.target.name,
                value: Globals.limitIntLength(e.target.value)
              }
            })}
            // disabled={change_price}
            disabled={true}
          />
        </div>
      )}
      <div className="col-md-6 col-lg-3">
        <InputFormat
          name="sale_price"
          maxLength={11}
          decimalScale={2}
          thousandSeparator={false}
          allowNegative={false}
          isNumericString={true}
          placeholder="0.00"
          label="Precio de venta"
          labelClass="material"
          customClass="material"
          value={form.sale_price}
          onChange={e => onChange({
            target: {
              name: e.target.name,
              value: Globals.limitIntLength(e.target.value)
            }
          })}
          disabled={change_price}
        />
      </div>
      <Prices
        prices={form.prices}
        onAdd={addPrice}
        onChange={changePrice}
        onDelete={deletePrice}
        change_price={change_price}
        onChangeCheck={changeDefaultPrice}
      />
    </>
  );
}

const SizeColor = ({ items, updateItems, createSelectItem, editSelectItem, sizes, colors, deleteSize, deleteColor, change_price, change_quantity, product_id }) => {
  const addItem = () => {
    const newItems = [ ...items, { ...initialSizeColorItem, prices: [] } ];
    updateItems(newItems);
  }

  const deleteItem = (itemIdx) => {
    const newItems = items.filter((_, i) => i !== itemIdx);
    updateItems(newItems);
  }

  const changeItem = (itemIdx, field, value) => {
    const newItems = [ ...items ];
    newItems[itemIdx][field] = value;
    updateItems(newItems);
  }

  const addPrice = (itemIdx) => {
    const newItems = [ ...items ];
    newItems[itemIdx].prices.push({ ...initialSizeColorPriceItem });
    updateItems(newItems);
  }

  const deletePrice = (itemIdx, priceIdx) => {
    const newItems = [ ...items ];
    newItems[itemIdx].prices = newItems[itemIdx].prices.filter((_, i) => i !== priceIdx);
    updateItems(newItems);
  }

  const changePrice = (itemIdx, priceIdx, value) => {
    const newItems = [ ...items ];
    newItems[itemIdx].prices[priceIdx].price = value;
    updateItems(newItems);
  }

  const changeDefaultPrice = (itemIdx, priceIdx, value) => {
    const newItems = [ ...items ];

    // Verificar si el valor es 1 y si ya existe otro precio con default_price en 1 dentro del mismo item
    if (value === 1 && newItems[itemIdx].prices.some(price => price.default_price === 1)) {
      Globals.showError('Solo puedes seleccionar un precio para mostrar en la web')
      return; // Detener la ejecución de la función
    }

    newItems[itemIdx].prices[priceIdx].default_price = value;
    updateItems(newItems);
  }

  const limitIntLength = (amount) => {
    let nums = String(amount).split('.', 2);
    let value = nums[0].substring(0, 8);
    return `${value}${nums[1] ? `.${nums[1]}` : ''}`;
  }

  return (
    <>
      <div className="col-md-12 py-4 d-flex justify-content-center align-items-center">
        <span className="mr-4">Tallas y colores</span>
        <Button
          title="Agregar talla y color"
          color="white"
          small="true"
          onClick={addItem}
        >
          <img src={AddIcon} width={24} alt="Action icon" />
        </Button>
      </div>

      {items.map((item, itemIdx) => (
        <div
          key={`size-color-${itemIdx}`}
          className="row mx-3 px-0 py-3"
          style={{ border: '2px solid #eee', borderRadius: '6px', position: 'relative' }}
        >
          <i
            className="fa fa-solid fa-trash position-absolute"
            style={{ color: 'red', top: '0.75rem', right: '1rem', cursor: 'pointer', fontSize: '24px', zIndex: 2 }}
            title="Eliminar"
            onClick={() => deleteItem(itemIdx)}
          />
          <div className="col-md-6">
            <SelectActions
              label="Talla"
              placeholder="Seleccione"
              actionOptions={[
                {
                  onClick: () => createSelectItem('modalSize'),
                  content: (
                    <div>
                      <span>Añadir</span>
                      <i className="fa fa-regular fa-plus ml-3" />
                    </div>
                  ),
                }
              ]}
              options={sizes.map((i, idx) => ({
                ...i,
                value: i.id,
                label: i.name,
                actions: [
                  <i
                    key={`size-${i.value}-edit`}
                    className="fa fa-regular fa-edit"
                    title="Editar"
                    onClick={() => editSelectItem('modalSize', i, idx)}
                  />,
                  <i
                    key={`size-${i.value}-close`}
                    className="fa fa-regular fa-close"
                    title="Eliminar"
                    onClick={() => deleteSize(i, idx)}
                    />,
                ],
              }))}
              value={item.size_id}
              onChange={v => changeItem(itemIdx, 'size_id', v)}
            />
          </div>
          <div className="col-md-6">
            <SelectActions
              label="Color"
              placeholder="Seleccione"
              actionOptions={[
                {
                  onClick: () => createSelectItem('modalColor'),
                  content: (
                    <div>
                      <span>Añadir</span>
                      <i className="fa fa-regular fa-plus ml-3" />
                    </div>
                  ),
                }
              ]}
              options={colors.map((i, idx) => ({
                ...i,
                value: i.id,
                label: i.name,
                actions: [
                  <i
                    key={`color-${i.value}-edit`}
                    className="fa fa-regular fa-edit"
                    title="Editar"
                    onClick={() => editSelectItem('modalColor', i, idx)}
                  />,
                  <i
                    key={`color-${i.value}-close`}
                    className="fa fa-regular fa-close"
                    title="Eliminar"
                    onClick={() => deleteColor(i, idx)}
                    />,
                ],
              }))}
              value={item.color_id}
              onChange={v => changeItem(itemIdx, 'color_id', v)}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <Input
              type="number"
              name="quantity"
              label="Cantidad"
              labelClass="material"
              className="material"
              value={item.quantity ?? item.stock}
              onChange={e => changeItem(itemIdx, e.target.name, e.target.value)}
              disabled={change_quantity}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <Input
              type="number"
              name="reservation"
              label="Reserva"
              labelClass="material"
              className="material"
              value={item.reservation}
              onChange={e => changeItem(itemIdx, e.target.name, e.target.value)}
              disabled={change_quantity}
            />
          </div>
          {product_id !== undefined && (
            <div className="col-md-6 col-lg-3">
              <InputFormat
                name="purchase_price"
                maxLength={11}
                decimalScale={2}
                thousandSeparator={false}
                allowNegative={false}
                isNumericString={true}
                placeholder="0.00"
                label="Precio de compra"
                labelClass="material"
                customClass="material"
                value={item.purchase_price}
                onChange={e => changeItem(itemIdx, e.target.name, limitIntLength(e.target.value))}
                // disabled={change_price}
                disabled={true}
              />
            </div>
          )}
          <div className="col-md-6 col-lg-3">
            <InputFormat
              name="sale_price"
              maxLength={11}
              decimalScale={2}
              thousandSeparator={false}
              allowNegative={false}
              isNumericString={true}
              placeholder="0.00"
              label="Precio de venta"
              labelClass="material"
              customClass="material"
              value={item.sale_price}
              onChange={e => changeItem(itemIdx, e.target.name, limitIntLength(e.target.value))}
              disabled={change_price}
            />
          </div>
          <Prices
            prices={item?.prices}
            onAdd={() => addPrice(itemIdx)}
            onChange={(priceIdx, value) => changePrice(itemIdx, priceIdx, value)}
            onDelete={priceIdx => deletePrice(itemIdx, priceIdx)}
            change_price={change_price}
            onChangeCheck={(priceIdx, value) => changeDefaultPrice(itemIdx, priceIdx, value)}
          />
        </div>
      ))}
    </>
  );
}

const Prices = ({ prices, onAdd, onDelete, onChange, change_price, onChangeCheck }) => (
  <div className="col-md-12">
    <div className="mb-2 d-flex justify-content-between align-items-center">
      <span>Precios de venta adicionales (Opcional)</span>
      <Button
        title="Agregar precio de venta adicional"
        color="white"
        small="true"
        onClick={onAdd}
      >
        <img src={AddIcon} width={24} alt="Action icon" />
      </Button>
    </div>
    <div
      className="row m-0 pt-2"
      style={{ border: '2px solid #eee', borderRadius: '6px', position: 'relative' }}
    >
      {prices?.map((price, priceIdx) => (
        <div key={`price-${priceIdx}`} className="col-md-4 col-lg-3">
          <InputFormat
            name="price"
            maxLength={11}
            decimalScale={2}
            thousandSeparator={false}
            allowNegative={false}
            isNumericString={true}
            placeholder="0.00"
            label="Precio"
            labelClass="material"
            customClass="material"
            value={price.price}
            onChange={e => onChange(priceIdx, Globals.limitIntLength(e.target.value))}
            disabled={change_price}
          />
          {/* OCULTO PARA PROD */}
          {/* {ENV.mode !== 3 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id={`default-price-${priceIdx}`}
                checked={price.default_price == Constants.PRODUCTS_DEFAULT_PRICE.YES ? true : false}
                onChange={() => onChangeCheck(priceIdx, price.default_price == Constants.PRODUCTS_DEFAULT_PRICE.NO ? Constants.PRODUCTS_DEFAULT_PRICE.YES : Constants.PRODUCTS_DEFAULT_PRICE.NO)}
                style={{ marginLeft: '10px' }}
              />
              <label htmlFor={`default-price-${priceIdx}`} style={{ marginLeft: '5px', fontWeight: 'bold' }}>Utilizar en la web</label>
            </div>
          ) : null} */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id={`default-price-${priceIdx}`}
              checked={price.default_price == Constants.PRODUCTS_DEFAULT_PRICE.YES ? true : false}
              onChange={() => onChangeCheck(priceIdx, price.default_price == Constants.PRODUCTS_DEFAULT_PRICE.NO ? Constants.PRODUCTS_DEFAULT_PRICE.YES : Constants.PRODUCTS_DEFAULT_PRICE.NO)}
              style={{ marginLeft: '10px' }}
            />
            <label htmlFor={`default-price-${priceIdx}`} style={{ marginLeft: '5px', fontWeight: 'bold' }}>Utilizar en la web</label>
          </div>
          <i
            className="fa fa-solid fa-trash position-absolute"
            style={{ color: 'red', top: '0.5rem', right: '1rem', cursor: 'pointer', fontSize: '20px', zIndex: 2 }}
            title="Eliminar"
            onClick={() => onDelete(priceIdx)}
          />
        </div>
      ))}

      {!prices?.length && (
        <div className="col-12 text-center mb-2">No tiene precios adicionales</div>
      )}
    </div>
  </div>
)

export default CreateEditProduct;
