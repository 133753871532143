import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Input,
  Select,
  CheckBox,
  Icon
} from "../../components";
import { axios, Constants, Globals, Format, ENV } from "../../utils";
import { Branch, Warehouse, Category, Brand } from "../../services";
import NumberFormat from "react-number-format";
import PrinterIcon from '../../assets/icons/printer.png';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thead: [],
      trows: [],
      total: 0,
      header: [
        {
          value: "code",
          label: "Código",
          type: 1,
          section: "required",
          order: 0
        },
        {
          value: "name",
          label: "Nombre",
          type: 1,
          section: "required",
          order: 1
        },
        {
          value: "brand",
          label: "Marca",
          type: 1,
          section: "required",
          order: 2
        },
        {
          value: "category",
          label: "Categoría",
          type: 1,
          section: "required",
          order: 3
        },
        {
          value: "current_stock",
          label: "Existencia",
          type: 1,
          section: "required",
          order: 4
        },
        {
          value: "unit",
          label: "Unidad",
          type: 1,
          section: "required",
          order: 5
        },
        {
          value: "committed",
          label: "Comprometido",
          type: 2,
          section: "stock",
          order: 6
        },
        {
          value: "available",
          label: "Disponible",
          type: 3,
          section: "conditional",
          order: 7
        },
        { value: "cost", label: "Costo", type: 2, section: "price", order: 9 },
        {
          value: "price",
          label: "Precio Min.",
          type: 2,
          section: "price",
          order: 8
        },
        {
          value: "price_max",
          label: "Precio Max.",
          type: 2,
          section: "price",
          order: 9
        },
        {
          value: "price_offer",
          label: "Precio Oferta",
          type: 2,
          section: "price",
          order: 10
        },
        {
          value: "total",
          label: "Total",
          type: 3,
          section: "conditional",
          order: 11
        }
      ],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
      ],
      page: 1,
      last_page: 1,
      data: [],
      quantity_products: 0,
      quantity_reservation: 0,
      total_orders: 0,
      total_purchases: 0,
      total_orders_bs: 0,
      total_purchases_bs: 0,
      costo_existencia: 0,
      branches: [],
      warehouses: [],
      user: null,
      view: false,
      providers: {},
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? this.props.user.warehouse_principal : this.props.user.warehouse_id,
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        code: true,
        name: true,
        current_stock: true,
        total: false,
        price: false,
        price_max: false,
        price_offer: false,
        cost: false,
        committed: false,
        available: false,
        web: true,
        currency: this.props.currency
      },
      categories: [],
      subcategories: [],
      brands: [],
      models: [],
      countPrice: 0,
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
      showProductsFilters: true
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getThead();
    this.getWarehouses();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.products();
    //this.getInventoryTotals();
  };

  getInventoryTotals = () => {
    let { form } = this.state;
    const { user } = this.props;

    form.user_id = user.id;
    form.web = true;
    
    axios
      .post("admin/reports/inventory/totals", form)
      .then(res => {
        this.setState({
          quantity_products: res.data.quantity_products,
          quantity_reservation: res.data.quantity_reservation,
          total_orders: res.data.total_orders,
          total_purchases: res.data.total_purchases,
          total_orders_bs: res.data.total_orders_bs,
          total_purchases_bs: res.data.total_purchases_bs,
          total: res.data.total_purchases,
          costo_existencia: res.data.costo_existencia,
        });
      })
      .catch(err => {
        Globals.showError();
      });
  }

  products = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    form.user_id = this.props.user.id;
    form.web = true;
    form.paginate = true;
    
    axios
      .post("admin/reports/inventory?page=" + page, form)
      .then(res => {
        console.log(res)
        this.setState({
          last_page: res.data.products.last_page,
          data: res.data.products.data,
          quantity_products: res.data.quantity_products,
          quantity_reservation: res.data.quantity_reservation,
          total_orders: res.data.total_orders,
          total_purchases: res.data.total_purchases,
          total_orders_bs: res.data.total_orders_bs,
          total_purchases_bs: res.data.total_purchases_bs,
          costo_existencia: res.data.costo_existencia,
          total: res.data.total_purchases
        });
      })
      .catch(err => {
        Globals.showError();
      });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getWarehouses = async () => {
    try {
      const data = {
        web: true,
        user_id: this.props.user.id,
        is_select: true,
        minimize: true,
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? null : this.props.user.warehouse_id,
      };

      const res = await axios.post('web/admin/warehouses', data);
      if (res.data?.warehouses) {
        const formatWarehouses = res.data.warehouses.map(Item => ({
          value: Item.id,
          label: Item.name
        }));

        const mainIdx = formatWarehouses.findIndex(x => x.value === this.props.user.warehouse_principal);
        if (mainIdx >= 0) {
          const main = formatWarehouses[mainIdx];
          formatWarehouses.splice(mainIdx, 1);
          formatWarehouses.unshift(main);
        }

        this.setState({
          warehouses: formatWarehouses
        });
      }
    } catch (error) {
      Globals.showError();
      console.log('NewRequest -> getWarehouses -> catch: ', error);
    }
  };

  getCategories = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };

    const { form } = this.state;

    Globals.setLoading();
    axios
      .post("admin/categories/get", param)
      .then(async res => {
        if (res.data.result) {
          this.setState({
            categories: res.data.categories,
            form: {
              ...form,
              subcategory_id: ""
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getSubcategories = categoryId => {
    let param = {
      category_id: categoryId,
      select: true
    };
    Category.getSubcategories(param).then(response => {
      if (response.result) {
        this.setState(state => ({
          ...state,
          subcategories: response.subcategories,
          form: {
            ...state.form,
            subcategory_id: ""
          }
        }));
      }
    });
  };

  getBrands = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };
    const { form } = this.state;
    Brand.getBrands(param).then(response => {
      if (response.result) {
        this.setState({
          brands: response.brands,
          form: {
            ...form,
            model_id: "",
            brand_id: ""
          }
        });
      }
    });
  };

  getModels = brandId => {
    let param = {
      brand_id: brandId,
      select: true,
      user_id: this.props.user.id
    };
    const { form } = this.state;
    Brand.getModels(param).then(response => {
      if (response.result) {
        this.setState({
          models: response.models,
          form: {
            ...form,
            model_id: ""
          }
        });
      }
    });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };
  openProductsDetails = e => {
    this.change(e);
    if (e.target.value === "")
      this.setState({
        showProductsFilters: false
      });
    else
      this.setState({
        showProductsFilters: true
      });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCheck = async e => {
    var { name, checked } = e.target;
    let { form, header, trows } = this.state;

    let sectionPrices = header
      .filter(Item => {
        return Item.section === "price";
      })
      .map(Item => {
        return Item.value;
      });

    let sectionStock = header
      .filter(Item => {
        return Item.section === "stock";
      })
      .map(Item => {
        return Item.value;
      });

    if (trows.length === 0) {
      trows = header.filter(Item => {
        return Item.type === 1;
      });
    } else {
      trows = trows.filter(Item => {
        return Item.type;
      });
    }

    let find = header.find(Item => Item.value === name);

    if (sectionPrices.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        trows.push(find);

        if (countSecPrice.length === 0) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = name;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        if (countSecPrice.length === 1) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = countSecPrice[0].value;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    if (sectionStock.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        trows.push(find);

        if (countSecStock.length === 0) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        if (countSecStock.length === 1) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    trows.sort((a, b) => parseInt(a.order) - parseInt(b.order));

    let thead = trows.map(Item => {
      return Item.label;
    });

    this.setState({
      form: {
        ...form,
        [name]: checked
      }
    });

    this.getThead(thead, trows);
    return;
  };

  getThead = async (arr = [], arr2 = []) => {
    const { header } = this.state;

    let newHeader = arr;

    if (newHeader.length === 0) {
      newHeader = header
        .filter(Item => {
          return Item.type === 1;
        })
        .map(Item => {
          return Item.label;
        });
    }

    await this.setState(state => {
      state.thead = newHeader;
      state.trows = arr2;
    });
  };

  getBranches = () => {
    let { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Branch.getBranchesByEnterprise({ Id: enterpriseId })
      .then(response => {
        let branches_filtered = response.filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const branchesMap = branches_filtered
          .filter(({ status }) => status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        this.setState({
          branches: branchesMap
        });
      })
      .catch(() => Globals.showError());
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      page: 1,
      search: '',
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? this.props.user.warehouse_principal : this.props.user.warehouse_id,
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: ""
      },
      thead: [],
      trows: [],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
      ],
    }));
    await this.getThead();
    // await this.getBranches();
    // await this.getCategories();
    // await this.getBrands();
    await this.load();
  };

  print = (data) => {
    Globals.currencyOptions(
      `Selecciona en que moneda deseas que se genere el reporte`,
      (currency) => {
        this.setState(state => ({
          form: {
            ...state.form,
            currency: currency
          }
        }));
        Globals.typeDownloadOptions(
          `Selecciona en que formato deseas que se exporte el reporte`,
          (type_download) => {
            this.setState(state => ({
              form: {
                ...state.form,
                type_download: type_download
              }
            }));
            axios
              .post("admin/reports/inventory/download", this.state.form)
              .then(res => {
                if (res.data.url_storage) {
                  //const url = res.data.url;
                  const url = `${ENV.BasePublic}${res.data.url_storage}`;
                  const win = window.open(url, "_blank");
                  win.focus();
                }
                else {
                  Globals.showError('Ha ocurrido un error');
                }
              })
              .catch(err => {
                Globals.showError();
              });
          }
        );
      }
    );
    
  };

  render() {
    const user = this.props.user
    const { trows } = this.state;
    const showTotal = trows.find(i => i.value == 'total') && trows.find(i => i.value == 'cost');

    const isLowResolution = window.innerWidth <= 1366 && window.innerHeight <= 768;
    const montoVentasTitle = isLowResolution ? "MONTO VENTAS" : "MONTO VENTAS";
    const montoComprasTitle = isLowResolution ? "MONTO COMPRAS" : "MONTO COMPRAS";

    return (
      <Menu history={this.props.history}>
        <div id="products">
          <div className="row">
            <div className="col-md-4 placeholder1">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.form.search}
                placeholder="Buscar por Nombre o código"
                onKeyDown={e => {
                  if (e.key === 'Enter') this.load();
                }}
              />
            </div>
            {user.level_id === Constants.ROLE_ADMIN || (user.level_id === Constants.ROLE_MODERATOR && user.warehouse_id === null) ? (
              <div className="col-md-4">
                <Select
                  color="white"
                  name="warehouse_id"
                  label="Almacén"
                  className="filter"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.change(emitter)}
                  value={this.state.form.warehouse_id}
                  options={this.state.warehouses}
                />
              </div>
            ) : null}
            <div className="col-md-2">
              <Button 
                className="btn-align-bottom btn-filter" 
                color=" " 
                onClick={async page => {
                  await this.setState({
                    page: 1
                  });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>
          </div>
          <div className="row" id="row1">

            <div className="col col-md d-flex flex-wrap justify-content-between align-items-center check">
              {this.state.optionals.map(el => {
                if (!el.show) {
                  return (
                    <div className="px-3 py-3 check1">
                      <CheckBox
                        label={el.label}
                        name={el.value}
                        checked={this.state.form[el.value] ? true : false}
                        value={this.state.form[el.value]}
                        onChange={this.handleCheck}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="row monthly-earnings mb-3 d-flex">
            <div className="col-6 col-sm-2 total-amount">
              <span className="" style={{ fontWeight: 'bold', fontSize: '20px' }}>NRO. PRODUCTOS</span>
              <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '20px' }}>{this.state.quantity_products}</div>
            </div>
            <div className="col-6 col-sm-2 total-amount">
              <span className="" style={{ fontWeight: 'bold', fontSize: '20px' }}>PROD. RESERVA</span>
              <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '20px' }}>{this.state.quantity_reservation}</div>
            </div>
            <div className="col-lg-2 total-amount">
              <span className="" style={{ fontWeight: 'bold', fontSize: '20px' }} title="Total $ de mercancía no vendida">COSTO EXISTENCIA</span>
              <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '20px' }}>{Globals.formatMiles(this.state.costo_existencia)} $</div>
            </div>
            <div className="col-lg-2 total-amount">
              <span className="" style={{ fontWeight: 'bold', fontSize: '20px' }}>{montoVentasTitle}</span>
              <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '20px' }}>{Globals.formatMiles(this.state.total_orders)} $</div>
            </div>
            <div className="col-lg-2 total-amount">
              <span className="" style={{ fontWeight: 'bold', fontSize: '20px' }}>{montoComprasTitle}</span>
              <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '20px' }}>{Globals.formatMiles(this.state.total_purchases)} $</div>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Reporte de inventario"
            header={this.state.thead}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print(this.state.data)}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.code}</td>
                  <td>
                    {i.name}
                  </td>
                  <td>
                    {i.brand?.name ?? 'No tiene'}
                  </td>
                  <td>
                    {i.category?.name ?? 'No tiene'}
                  </td>
                  <td>
                    {i.stock_warehouse !== undefined && i.stock_warehouse !== null
                      ? i.stock_warehouse
                      : i.stock_acumulado > 0
                        ? i.stock_acumulado
                        : i.stock}
                  </td>
                  <td>{i.unit}</td>
                </tr>
              );
            })}
          </Table>

          {
            showTotal && (
              <p style={{
                fontSize: '16px',
                fontWeight: 'normal',
                fontFamily: 'Roboto Light',
                textAlign: 'right',
                marginTop: '10px',
                marginRight: '10px'
              }}>Costo Total: <NumberFormat
                  value={parseFloat(this.state.total.toFixed(2))}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
            )
          }

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(Reports);
