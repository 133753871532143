import React, { createRef } from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import { Globals, ENV, Constants, SocketEvents } from "../../utils";
import { User as UserService } from "../../services";
import BusinessIcon from '../../assets/icons/my-business.png';
import ProfileIcon from '../../assets/icons/my-profile.png';
import PictureIcon from '../../assets/icons/image.png';
import socket from '../../utils/socket';
import { Share } from "../../components";
import TrashIcon from '../../assets/icons/trash.png';

const SHOW_PRICES = {
	YES: 1,
	NO: 0
}

const ACCEPT_ORDER_BEFORE = {
	YES: 1,
	NO: 0
}

const TABS = {
	ACCOUNT: 1,
	BUSINESS: 2,
	GENERAL_CONFIGS: 3,
}

class MyProfileAccount extends React.Component {

	state = {
		tab: this.props.user.level_id === Constants.ROLE_ADMIN ? TABS.BUSINESS : TABS.ACCOUNT,
		form: {
			logo: {},
			logoUrl: '',
			password: '',
			confirmPassword: '',
			name: '',
			email: '',
			document: '',
			phone: '',
			address: '',
			show_prices: SHOW_PRICES.YES,
			url: ENV.BaseStore,
			formattedUrl: '',
			sell_note: '',
			facebook: '',
			instagram: '',
			twitter: '',
			header_bg: '#C70FB1',
			footer_bg: '#333333',
			banner: '',
			bannerUrl: '',
			accept_order_before: ACCEPT_ORDER_BEFORE.NO,
		},
		currentURL: ENV.BaseStore,
		store_session: `${this.props.user.id}-${Date.now()}`,
	};

	componentDidMount() {
		this.assignForm();
		this.load();
	}

	debounce = createRef();

	load = async () => {
		Globals.setLoading();

		try {
			const res = await UserService.getAdminProfile({
				user_id: this.props.user.id
			});

			if (!res.user) throw new Error('Ocurrió un error inesperado');

			await this.setState({
				form: {
					...this.state.form,
					show_prices: res.public_store?.show_prices,
					url: res.public_store?.url || '',
					facebook: res.user.facebook || '',
					instagram: res.user.instagram || '',
					twitter: res.user.twitter || '',
					header_bg: res.public_store?.header_bg || '#C70FB1',
					footer_bg: res.public_store?.footer_bg || '#333333',
					banner: res.public_store?.banner || '',
					bannerUrl: res.public_store?.banner ? Globals.fromPhotos(res.public_store.banner) : '',
					accept_order_before: res.user.accept_order_before,
				},
				currentURL: ENV.BaseStore + (res.public_store?.url || ''),
			});
			await this.formatUrl();

		} catch (error) {
			console.log(error);
			Globals.showError('Ocurrió un error inesperado');
		}

		Globals.quitLoading();
	}

	assignForm = async () => {
		await this.setState({
			form: {
				...this.state.form,
				logoUrl: this.props.user.logo ? Globals.fromPhotos(this.props.user.logo) : '',
				name: this.props.user?.name || '',
				email: this.props.user?.email || '',
				document: this.props.user?.document || '',
				phone: this.props.user?.phone || '',
				address: this.props.user?.address || '',
				sell_note: this.props.user?.sell_note || '',
				facebook: this.props.user?.facebook || '',
				instagram: this.props.user?.instagram || '',
				twitter: this.props.user?.twitter || '',
			}
		});
	}

	onChangeEvent = async (event) => {
		const target = event.target.name;
		const value = event.target.value;

		await this.setState(state => ({
			form: { ...state.form, [target]: value }
		}));

		if (!['header_bg','footer_bg'].includes(target)) return;

		if (this.debounce.current) clearTimeout(this.debounce.current);
		this.debounce.current = setTimeout(async () => {
			socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.COLORS, {
				header_bg: this.state.form.header_bg,
				footer_bg: this.state.form.footer_bg,
				store_session: this.state.store_session,
			});
		}, 500);
	}

	onChange = async (value, target) => {
		await this.setState(state => ({
			form: {
				...state.form,
				[target]: !!value?.target ? value?.target?.value : value,
			}
		}));
	}

	formatUrl = async () => {
		await this.setState(state => ({
			form: {
				...state.form,
				formattedUrl: ENV.BaseStore + state.form.url,
			}
		}));
	}

	uploadPhoto = async (e) => {
    const currentFile = URL.createObjectURL(e.target.files[0]);
		await this.onChange(e.target.files[0], 'logo');
		await this.onChange(currentFile, 'logoUrl');
	}

	setTab = (tab) => {
		this.setState({ tab });
	}

	onSubmit = async () => {
		if (!this.isValidForm()) return;
		Globals.setLoading();

		try {
			const res = await UserService.editAdminProfile({
				web: true,
				user_id: this.props.user.id,
				...this.state.form,
				hasFile: true,
			});

			if (!res.user) throw new Error('Ocurrió un error inesperado');

			this.props.dispatch({
				type: 'SET_USER',
				payload: res.user,
			});
			Globals.showSuccess('Se han guardado los cambios');

		} catch (error) {
			console.log(error);
			Globals.showError(error?.error || 'Ocurrió un error inesperado');
		}

		Globals.quitLoading();
	}

	isValidForm = () => {
		const onError = (message) => {
			Globals.showError(message);
			return false;
		}

		const { form } = this.state;

		if (!form.email){
			return onError('El correo es obligatorio');
		}

		if (!!form.password || !!form.confirmPassword) {
			if (!form.password)
				return onError('La contraseña es obligatoria');
		
			if (form.password !== form.confirmPassword)
				return onError('Las contraseñas no coinciden');
		}

		if(this.props.user.level_id === Constants.ROLE_ADMIN){
			if (!form.url){
				return onError('La URL es obligatoria');
			}	
	
			if (form.url.includes(' ')){
				return onError('La URL no puede tener espacios en blanco');
			}
		}

		return true;
	}

	shareURL = () => {
		if (!navigator.share)
			return Globals.showError('Tu navegador no permite esta función');

		try {
			navigator.share({
				title: 'Catálogo Virtual',
				url: this.state.form.formattedUrl,
			});

		} catch (error) {
			console.log(error)
		}
	}

	onUploadBanner = async (e) => {
		if (!e.target.files[0]) return;
		if (!['image/jpeg','image/png','image/gif'].includes(e.target.files[0].type))
			return Globals.showError('La imagen debe ser formato JPG o PNG');
		if (e.target.files[0].size > 500000)
			return Globals.showError('La imagen no puede pesar más de 500KB');

    const currentFile = URL.createObjectURL(e.target.files[0]);
		const file = new FileReader();
		file.readAsDataURL(e.target.files[0]);
		file.onload = () => {
			socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, {
				banner: file.result,
				store_session: this.state.store_session,
			});
		}
		await this.onChange(e.target.files[0], 'banner');
		await this.onChange(currentFile, 'bannerUrl');
	}

	onRemoveBanner = async () => {
		await this.onChange('', 'banner');
		await this.onChange('', 'bannerUrl');
		document.getElementById('banner-image').value = null;
		socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, {
			banner: null,
			store_session: this.state.store_session,
		});
	}

	render() {

		const { history } = this.props;
		const { form, tab } = this.state;
		const photo = form?.logoUrl || PictureIcon;
		const banner = form?.bannerUrl || PictureIcon;

		return (
			<Menu history={history}>
				<div id="my-profile-account">
					<nav>
						<button
							className={tab === TABS.ACCOUNT ? 'active': ''}
							onClick={() => this.setTab(TABS.ACCOUNT)}
						>
							<img src={ProfileIcon} alt="Mi cuenta" />
							<span>Mi Cuenta</span>
						</button>
						{this.props.user.level_id === Constants.ROLE_ADMIN && (
							<button
								className={tab === TABS.BUSINESS ? 'active': ''}
								onClick={() => this.setTab(TABS.BUSINESS)}
							>
								<img src={BusinessIcon} alt="Mi negocio" />
								<span>Mi Negocio</span>
							</button>
						)}

						{this.props.user.level_id === Constants.ROLE_ADMIN && (
							<button
								className={tab === TABS.GENERAL_CONFIGS ? 'active' : ''}
								onClick={() => this.setTab(TABS.GENERAL_CONFIGS)}
							>
								{/* <img src={BusinessIcon} alt="Configuraciones generales" /> */}
								<span>Configuraciones{'\n'}generales</span>
							</button>
						)}
					</nav>

					{tab === TABS.ACCOUNT && (
						<main className="account-tab">
							<div className="control-group">
								<div className="control w-full">
									<label>Correo</label>
									<input
										name="email"
										value={form.email}
										onChange={this.onChangeEvent}
									/>
								</div>
								<p className="control w-full title-section">Cambiar contraseña</p>
								<div className="control">
									<label>Nueva contraseña</label>
									<input
										type="password"
										placeholder="******"
										name="password"
										value={form.password}
										onChange={this.onChangeEvent}
									/>
								</div>
								<div className="control">
									<label>Repetir contraseña</label>
									<input
										type="password"
										placeholder="******"
										name="confirmPassword"
										value={form.confirmPassword}
										onChange={this.onChangeEvent}
									/>
								</div>
							</div>
							<div className="actions">
								<button className="primary" onClick={this.onSubmit}>
									<span>GUARDAR</span>
								</button>
							</div>
						</main>
					)}

					{tab === TABS.BUSINESS && (
						<main className="business-tab">
							<section className="profile">
								<div className="avatar-wrapper">
									<label className="avatar" htmlFor="upload-photo">
										<img
											src={photo}
											className={photo === PictureIcon ? 'placeholder' : ''}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.classList.add('placeholder');
												currentTarget.src = PictureIcon;
											}}
											alt="Foto de perfil"
										/>
										<input
											type="file"
											id="upload-photo"
											onChange={this.uploadPhoto}
										/>
									</label>
									<div className="filename">Foto de logo</div>
								</div>
								<div className="control-group">
									<div className="control">
										<label>Nombre</label>
										<input
											name="name"
											value={form.name}
											onChange={this.onChangeEvent}
										/>
									</div>
									<div className="control">
										<label>RIF</label>
										<input
											name="document"
											value={form.document}
											onChange={this.onChangeEvent}
										/>
									</div>
									<div className="control">
										<label>Teléfono</label>
										<input
											name="phone"
											value={form.phone}
											onChange={this.onChangeEvent}
										/>
									</div>
									<div className="control">
										<label>Dirección fiscal</label>
										<input
											name="address"
											value={form.address}
											onChange={this.onChangeEvent}
										/>
									</div>
									<div className="control w-full">
										<label>Políticas notas de venta</label>
										<input
											name="sell_note"
											value={form.sell_note}
											onChange={this.onChangeEvent}
										/>
									</div>									
								</div>
							</section>

							<section className="catalogue">
								<div className="section-big">
									<p>TIENDA PÚBLICA WEB (Catálogo virtual)</p>
								</div>

								<p className="section-label">1) Configurar dirección pública (como los clientes entrarán a tu página)</p>
								<section>
									<div className="url-control">
										<div className="prefix">
											<span>URL</span>
										</div>
										<input
											name="url"
											value={form.url}
											autoComplete="none"
											onChange={async (event) => {
												await this.onChangeEvent(event);
												await this.formatUrl();
											}}
										/>
										<button onClick={() => Globals.copyToClipboard(this.state.form.formattedUrl)} />
									</div>
									<p className="link">{ form.formattedUrl }</p>
									<div className="actions">
										<p>Comparte tu enlace para que puedan ver tu catálogo</p>
										<Share url={form.formattedUrl} />
									</div>
								</section>

								<p className="section-label">2) Configuración de la tienda</p>
								<section>
									<div className="show-prices">
										<p>¿Deseas que la Web tenga público los precios?</p>
										<div className="checkbox-items">
											<div
												className="checkbox-item"
												onClick={() => this.onChange(SHOW_PRICES.YES, 'show_prices')}
											>
												<input id="show-prices-yes"
													type="checkbox"
													checked={form.show_prices}
													onChange={() => this.onChange(SHOW_PRICES.YES, 'show_prices')}
												/>
												<label htmlFor="show-prices-yes">Sí</label>
											</div>

											<div
												className="checkbox-item"
												onClick={() => this.onChange(SHOW_PRICES.NO, 'show_prices')}
											>
												<input id="show-prices-no"
													type="checkbox"
													checked={!form.show_prices}
													onChange={() => this.onChange(SHOW_PRICES.NO, 'show_prices')}
												/>
												<label htmlFor="show-prices-no">No</label>
											</div>
										</div>
									</div>

									<p>Redes sociales</p>
									<div className="social">
										<label>Facebook</label>
										<input
											placeholder="URL"
											name="facebook"
											value={form.facebook}
											onChange={this.onChangeEvent}
										/>
										<label>Instagram</label>
										<input
											placeholder="URL"
											name="instagram"
											value={form.instagram}
											onChange={this.onChangeEvent}
										/>
										<label>X (Twitter)</label>
										<input
											placeholder="URL"
											name="twitter"
											value={form.twitter}
											onChange={this.onChangeEvent}
										/>
									</div>
								</section>

								<p className="section-label">3) Apariencia del sitio</p>
								<section>
									<div className="appearance">
										<div>
											<p>Barra superior / barra del menú</p>
											<div className="control">
												<label>Color</label>
												<input
													type="color"
													name="header_bg"
													value={form.header_bg}
													onChange={this.onChangeEvent}
												/>
											</div>

											<p>Banner imagen</p>
											<label style={{ fontSize: 12 }}>(medida sugerida 1900px por 400px, máximo 500KB)</label>
											<div className="banner-upload">
												<label className="banner-input">
													<div className="banner-preview">
														<img
															src={banner}
															className={banner === PictureIcon ? 'placeholder' : ''}
															onError={({ currentTarget }) => {
																currentTarget.onerror = null;
																currentTarget.classList.add('placeholder');
																currentTarget.src = PictureIcon;
															}}
															alt="Banner"
														/>
													</div>
													<input
														type="file"
														accept="image/*"
														name="banner"
														id="banner-image"
														onChange={this.onUploadBanner}
													/>
												</label>
												<img
													src={TrashIcon}
													className="banner-upload__delete"
													alt="Eliminar"
													title="Eliminar banner"
													onClick={this.onRemoveBanner}
												/>
											</div>

											<p>Pie de página - footer</p>
											<div className="control">
												<label>Color</label>
												<input
													type="color"
													name="footer_bg"
													value={form.footer_bg}
													onChange={this.onChangeEvent}
												/>
											</div>
										</div>
										<div className="preview">
											{this.state.currentURL !== ENV.BaseStore ? (
												<StorePreview currentURL={this.state.currentURL} store_session={this.state.store_session} />
											) : (
												<p>No tiene URL de la tienda para mostrar</p>
											)}
										</div>
									</div>
									<button className="save-btn primary" onClick={this.onSubmit}>
										<span>GUARDAR</span>
									</button>
								</section>
							</section>
						</main>
					)}

					{tab === TABS.GENERAL_CONFIGS && (
						<main className="business-tab">
							<section className="catalogue">
								<br></br>
								<section>
									<div className="show-prices">
										<p>¿El administrador debe aceptar los pedidos para descontar del inventario?</p>
										<div className="checkbox-items">
											<div
												className="checkbox-item"
												onClick={() => this.onChange(ACCEPT_ORDER_BEFORE.YES, 'accept_order_before')}
											>
												<input id="show-prices-yes"
													type="checkbox"
													checked={form.accept_order_before}
													onChange={() => this.onChange(ACCEPT_ORDER_BEFORE.YES, 'accept_order_before')}
												/>
												<label htmlFor="show-prices-yes">Sí</label>
											</div>

											<div
												className="checkbox-item"
												onClick={() => this.onChange(ACCEPT_ORDER_BEFORE.NO, 'accept_order_before')}
											>
												<input id="show-prices-no"
													type="checkbox"
													checked={!form.accept_order_before}
													onChange={() => this.onChange(ACCEPT_ORDER_BEFORE.NO, 'accept_order_before')}
												/>
												<label htmlFor="show-prices-no">No</label>
											</div>
										</div>
									</div>
									<button className="save-btn primary" onClick={this.onSubmit}>
										<span>GUARDAR</span>
									</button>
								</section>

							</section>
						</main>
					)}
				</div>
			</Menu>
		);
	}
}

const StorePreview = React.memo(({ currentURL, store_session }) => (
	<iframe
		title="Vista previa"
		src={`${currentURL}${Globals.toParams({ store_session })}`}
	/>
))

export default connect(state => {
	return {
		user: state.user
	};
})(MyProfileAccount);
