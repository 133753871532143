import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Menu from "../menu";
import { DatePicker, Select, Modal, Button, Table, Pagination, Icon } from '../../components';
import { Globals, Constants, axios } from '../../utils';
import { PublicStoreService } from '../../services';
import ConvertToOrder from './convert-to-order';
import SelectClient from './select-client';
import TrashIcon from '../../assets/icons/trash.png';
import ThumbUpIcon from '../../assets/icons/thumb-up.png';
import ThumbDownIcon from '../../assets/icons/thumb-down.png';
import Swal from 'sweetalert2';

const INITIAL_STATE = {
	until: '',
	since: '',
	status: ''
}

const {
	PENDING,
	PROCESSED,
	REJECTED,
	PENDING_LABEL,
	PROCESSED_LABEL,
	REJECTED_LABEL,
} = Constants.ORDER_STATUS;

const STATUS_ORDER = [
	{ value: PENDING, label: PENDING_LABEL },
	{ value: PROCESSED, label: PROCESSED_LABEL },
	{ value: REJECTED, label: REJECTED_LABEL },
];

class Whatsapp extends React.Component {

	subscriber = null;

	state = {
		page: 1,
		form: INITIAL_STATE,
		data: [],
		item: null,
		active: null,
		visible: false,
		visible_client: false,
		visible_details: false,
		client: null,
		zones: [],
		states: [],
		methods: [],
		header: ['ID','Fecha','Monto','Estatus','Acciones'],
	}

	componentDidMount() {
		this.getOrders();
		this.getZones();
		this.getStates();
	}

	getOrders = async (page = 1) => {
		const { form } = this.state;

		const since = moment(form.since).isValid()
			? moment(form.since).format('YYYY-MM-DD')
			: null;

		const until = moment(form.until).isValid()
			? moment(form.until).format('YYYY-MM-DD')
			: null;

		Globals.setLoading();
		try {
			const res = await PublicStoreService.orders({
				//admin_id: this.props.user.id,
				admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
				status: form.status || null,
				since,
				until,
				page,
			});

			const data = res?.ws_orders?.data?.map(order => {
				order.details.forEach(product => {
					product.sale_price = this.getPrice(product);
				});

				order.total = order.details.map(product => {
					return product?.sale_price * product.quantity;
				}).reduce((a,b) => a + b, 0);

				return order;
			}) || [];

			this.setState({
				data,
				page,
				last_page: res?.ws_orders?.last_page,
				methods: res?.payment_methods,
			});

		} catch (error) {
			console.log('Error: getOrders');
		}
		Globals.quitLoading();
	}

  getZones = () => {
    axios
      .post("web/admin/zones", {user_id: this.props.user.id})
      .then(({ data }) => {
        this.setState({ zones: data.zones });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

	getStates = () => {
		axios
			.post("web/admin/managers/states", { user_id: this.props.user.id })
			.then(({ data }) => {
					this.setState({ states: data.states });
			})
			.catch(() => Globals.showError())
			.then(() => Globals.quitLoading());
	};

	change = (value,target) => {
		this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		});
	}

	reset = async () => {
		await this.setState({ form: INITIAL_STATE });
		this.getOrders();
	}

	delete = (item) => {
		Globals.confirm('¿Desea eliminar el pedido?', async () => {
			await PublicStoreService.delete({
				order_id: item.id,
			});
			Globals.showSuccess("Se ha eliminado el pedido correctamente");
			this.getOrders();
		});
	}

	viewDetails = (item) => {
		this.setState({ visible_details: true, item });
	}

	openClients = (item) => {
		this.setState({ visible_client: true, item });
	}

	convertToOrder = (item) => {
		this.setState({ visible: true, item });
	}

	reject = (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo de rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe ingresar el motivo de rechazo');

        const form = {
					order_id: item.id,
					comments,
					currency: this.props.currency,
					user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return PublicStoreService.reject(form)
          .then(() => {
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al rechazar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha rechazado el pedido correctamente');
        this.getOrders();
      }
    });
	}

	setActive = (active) => {
		if (this.state.active != active) {
			this.setState({
				active
			});
		}
		else {
			this.setState({
				active: null
			});
		}
	}

	getColor = (status) => {
		switch(status) {
			case PENDING:
				return '#3A7FC2';

			case PROCESSED:
				return '#38CE6A';

			case REJECTED:
				return '#DD4B4B';

			default:
				return '#DD4B4B';
		}
	}

	getStatusLabel = (status) => {
		let text = "";
		switch(status) {
			case PENDING:
				text = PENDING_LABEL;
			break;

			case PROCESSED:
				text = PROCESSED_LABEL;
			break;

			case REJECTED:
				text = REJECTED_LABEL;
			break;
		}
		return text;
	}

	getPrice = (product) => {
		let size_color = null;

		if(product?.product?.type == Constants.PRODUCTS_TYPE.NORMAL) {
			let price = 0
			if(product?.product?.default_price === undefined || product?.product?.default_price === null || product?.product?.default_price === Constants.PRODUCTS_DEFAULT_PRICE.YES){
				price = product?.product?.sale_price
			}
			else {
				// Busca el primer objeto en product.prices donde default_price es 1
				const priceObj = product?.product?.prices.find(price => price.default_price === 1);
				// Si se encuentra un objeto que cumple con la condición, asigna su price a price
				if (priceObj) {
					price = priceObj.price;
				}
			}

			return price
		}
		else if (product?.product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
			size_color = product?.product?.size_colors?.find((i) => {
				if (product.size_id && product.color_id) {
					return i.size_id == product.size_id && i.color_id == product.color_id;
				}
				else if (product.size_id) {
					return i.size_id == product.size_id && i.color_id == null;
				}
				else if (product.color_id) {
					return i.color_id == product.color_id && i.size_id == null;
				}
			});

			return size_color?.sale_price || 0
		}
	}

	onClose = async (move = false) => {
		await this.setState({
			visible: false,
			visible_client: false,
			visible_details: false
		});

		if (move) {
			this.getOrders();
		}
	}

	onCloseClient = (client = null) => {
		this.setState({
			visible_client: false,
		},() => {
			if (client) {
				this.setState({
					client
				},() => {
					this.convertToOrder(this.state.item);
				});
			}
		});
	}

	render() {
		const { data, visible, visible_client, visible_details, item, zones, states, methods } = this.state;

		return (
      <Menu history={this.props.history}>
				{visible_client && (
					<Modal
						title="Seleccionar cliente"
						onClose={ (client = null) => this.onCloseClient(client) }
						visible
					>
						<SelectClient
							item={ item }
							zones={ zones }
							states={ states }
							onClose={ this.onCloseClient }
						/>
					</Modal>
				)}

				{visible && (
					<Modal
						title="Enviar a pedido"
						onClose={ () => this.onClose() }
						visible
					>
						<ConvertToOrder
							client={ this.state.client }
							item={ item }
							methods={ methods }
							onClose={ this.onClose }
						/>
					</Modal>
				)}

				{visible_details && (
					<Modal
						title="Detalles del pedido"
						onClose={ () => this.onClose() }
						visible
					>
						<OrderDetails
							order={ item }
							onClose={ this.onClose }
							onAccept={() => {
								this.onClose();
								this.openClients(item);
							}}
							onReject={() => {
								this.onClose();
								this.reject(item);
							}}
							onDelete={() => {
								this.onClose();
								this.delete(item);
							}}
						/>
					</Modal>
				)}

				<div className="row">
					<div className="col-3">
						<DatePicker
							color="white"
							label="Desde"
							className="filter"
							value={this.state.form.since}
							onChange={value => {
								this.setState(state => ({
									form: {
										...state.form,
										since: value
									}
								}));
							}}
						/>
					</div>
					<div className="col-3">
						<DatePicker
							color="white"
							label="Hasta"
							className="filter"
							value={this.state.form.until}
							onChange={value => {
								this.setState(state => ({
									form: {
										...state.form,
										until: value
									}
								}));
							}}
						/>
					</div>
					<div className="col-3">
						<Select
							color="white"
							name="type"
							label="Estatus"
							className="filter"
							onChange={ e => {
								this.setState({
									form: {
										...this.state.form,
										status: e.target.value
									}
								});
							} }
							options={ STATUS_ORDER.map(i => {
								return {
									value: i.value,
									label: i.label
								}
							}) }
							value={ this.state.form.status }
							defaultname="Seleccione"
						/>
					</div>
					<div className="col-3 px-0" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
						<Button
							className="btn-align-bottom btn-filter"
							color=" "
							style={{ minWidth: 100 }}
							onClick={async () => this.getOrders()}
						>
							<span>Filtrar</span>
						</Button>
						<Button
							className="btn-align-bottom btn-filter"
							color=" "
							style={{ minWidth: 100 }}
							onClick={this.reset}
						>
							<span>Limpiar</span>
						</Button>
					</div>
				</div>

				<div>
					<Table
						data={data.length}
						title="Pedidos de Whatsapp"
						header={this.state.header}
					>
						{data.map((item, index) => {
							return (
								<tr key={index}>
									<td>
										{ item.id }
									</td>
									<td>
										{ moment(item.created_at).format('DD/MM/YYYY') }
									</td>
									<td>
										{ Globals.formatMiles(item.total,true) }
									</td>
									<td style={{ color: this.getColor(item.status) }}>
										{ this.getStatusLabel(item.status) }
									</td>
									<td>
										<Button
											color="green"
											title="Ver detalles"
											small="true"
											onClick={() => this.viewDetails(item)}
										>
											<Icon name="eye" />
										</Button>
										{item.status === Constants.ORDER_STATUS.PENDING && (
											<Button
												color="green"
												title="Aceptar"
												small="true"
												onClick={() => this.openClients(item)}
											>
												<img src={ThumbUpIcon} width={14} alt="Aceptar" />
											</Button>
										)}
										{item.status === Constants.ORDER_STATUS.PENDING && (
											<Button
												color="red"
												title="Rechazar"
												small="true"
												onClick={() => this.reject(item)}
											>
												<img src={ThumbDownIcon} width={14} alt="Rechazar" />
											</Button>
										)}

										<Button
											color="red"
											title="Eliminar"
											small="true"
											onClick={() => this.delete(item)}
										>
											<Icon name="trash" />
										</Button>
									</td>
								</tr>
							);
						})}
					</Table>

					<Pagination
						pages={this.state.last_page}
						active={this.state.page}
						onChange={async page => {
							this.getOrders(page);
						}}
					/>
				</div>
			</Menu>
		)
	}
}

const OrderDetails = ({ order, onClose, onDelete, onReject, onAccept }) => {
	return (
		<div className="whatsapp-order-details">
			{order.details.map((product) => {
				if (product?.product) {
					return (
						<div className="product-item">
							<div className="product-info">
								<div>x{ product?.quantity || 0 } - { product?.product?.name || '-' }</div>
								<div>$ { Globals.formatMiles(product?.sale_price * product?.quantity,true) }</div>
							</div>
							<div className="product-details">
								{product.color_id && (
									<div className="product-color" style={{ backgroundColor: product.color?.hex }} />
								)}
								{product.size_id && (
									<div className="product-size">{ product.size?.name }</div>
								)}
							</div>
						</div>
					)
				}
				else {
					return null;
				}
			})}

			<div className="actions">
				<button className="delete" onClick={ onDelete } title="Eliminar pedido">
					<img src={ TrashIcon } width={22} alt="Eliminar" />
				</button>

				{PENDING == order.status && (
					<div className="accept-reject">
						<Button
							color="red"
							onClick={ onReject }
						>
							<span style={ styles.buttonText }>Rechazar</span>
						</Button>

						<Button
							color="green"
							onClick={ onAccept }
						>
							<span style={ styles.buttonText }>Aceptar</span>
						</Button>
					</div>
				)}
			</div>

		</div>
	)
}

const styles = {};

export default connect(state => {
	return {
		user: state.user,
    currency: state.currency,
	}
})(Whatsapp);