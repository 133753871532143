import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { Modal as RBModal } from "react-bootstrap";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  DatePicker,
  Modal,
  Input,
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import PrinterIcon from "../../assets/icons/printer.png";
import FilterIcon from "../../assets/icons/icono_filtro.jpg";
import CloseIcon from "../../assets/icons/icono_cerrar.png";

const ORDER_STATUS = {
  PENDING: 0,
  PROCESSED: 1,
  REJECTED: 2,
  CANCELLED: 5,
  DISPATCHED: 6,
  DELETED: 'deleted_at',
}

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thead: [],
      trows: [],
      heading: {
        quantity: 0,
        total: 0,
      },
      header: [
        {
          value: "correlative_number",
          label: "ID",
          type: 1,
          section: "required",
          order: 0
        },
        {
          value: "created_at",
          label: "Fecha",
          type: 1,
          section: "required",
          order: 1
        },
        {
          value: "seller",
          label: "Vendedor",
          type: 1,
          section: "required",
          order: 2
        },
        {
          value: "client",
          label: "Cliente",
          type: 1,
          section: "required",
          order: 3
        },
        {
          value: "",
          label: "Categoría",
          type: 1,
          section: "required",
          order: 4
        },
        {
          value: "amount",
          label: "Monto",
          type: 1,
          section: "required",
          order: 5
        },
        {
          value: "payment_method",
          label: "Método de pago",
          type: 1,
          section: "required",
          order: 8
        },
        {
          value: "status_payment",
          label: "Estatus de pago",
          type: 1,
          section: "required",
          order: 9
        },
      ],
      page: 1,
      last_page: 1,
      data: [],
      form: {
        status_payment: "",
        status: ORDER_STATUS.PROCESSED,
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        order_id: "",
        code: true,
        name: true,
        current_stock: true,
        total: false,
        price: false,
        price_max: false,
        price_offer: false,
        cost: false,
        committed: false,
        available: false,
        since: "",
        until: "",
        payment_method_id: "",
        client_id: "",
        seller_id: "",
        web: true,
        with_categories_text: true,
        with_moderators: true,
        type_download: 'pdf',
        output: 'sales-report',
        currency: Constants.CURRENCIES.DOLARES,
      },
      categories: [],
      paymentMethods: [],
      clients: [],
      sellers: [],
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
      dates: {
        minDate: "",
        maxDate: ""
      },
      statuses: [
        {value: ORDER_STATUS.PENDING, label: 'Pendiente/En proceso'},
        {value: ORDER_STATUS.PROCESSED, label: 'Procesado'},
        {value: ORDER_STATUS.REJECTED, label: 'Rechazado'},
        {value: ORDER_STATUS.CANCELLED, label: 'Anulado'},
        {value: ORDER_STATUS.DELETED, label: 'Eliminado'},
        //{value: ORDER_STATUS.DISPATCHED, label: 'Despachado'},
      ],
      selectedItem: null,
      paymentStatuses: [
        {value: Constants.PAYMENT_TYPES.PAID, label: 'Pagado'},
        {value: Constants.PAYMENT_TYPES.TO_PAY, label: 'Por Pagar'},
      ],
      toPrint: null,
      showFilters: false,
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getThead();
    this.getCategories();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.getOrders();
  };

  getOrders = (resetPage = null) => {
    let { page, form } = this.state;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    form.user_id = this.props.user.id;
    form.since = !!form.since ? moment(form.since).format('YYYY-MM-DD') : '';
    form.until = !!form.until ? moment(form.until).format('YYYY-MM-DD') : '';

    Globals.setLoading();

    axios
      .post("admin/reports/orders?page=" + page, form)
      .then(res => {
        const paymentMethodsMap = res.data.payment_methods.map(({ id, name }) => ({
            value: id,
            label: name
          }));
        const clientsMap = res.data.clients.map(({ id, name }) => ({
            value: id,
            label: name
          }));
        const sellersMap = [
          ...res.data.sellers,
          ...res.data.managers,
          ...res.data.moderators,
        ].map(({ id, name }) => ({ value: id, label: name }));
        this.setState({
          last_page: res.data.orders.last_page,
          data: res.data.orders.data,
          paymentMethods: paymentMethodsMap,
          clients: clientsMap,
          sellers: sellersMap,
          heading: {
            quantity: res.data.orders.total,
            total: res.data.totales.total,
          }
        });
      })
      .catch(err => {
        Globals.showError();
      })
      .finally(() => Globals.quitLoading());
  };

  getCategories = () => {
    const data = {
      user_id: this.props.user.id,
    };

    axios
      .post("admin/inventory/categories", data)
      .then(res => {
        const categories = res.data?.categories?.map(({ id, name }) => ({
            value: id,
            label: name
          })) || [];

        this.setState({ categories });
      })
      .catch(err => {
        Globals.showError();
      });
  }

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  getThead = async (arr = [], arr2 = []) => {
    const { header } = this.state;

    let newHeader = arr;

    if (newHeader.length === 0) {
      newHeader = header
        .filter(Item => {
          return Item.type === 1;
        })
        .map(Item => {
          return Item.label;
        });
    }

    await this.setState(state => {
      state.thead = newHeader;
      state.trows = arr2;
    });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        status_payment: "",
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        payment_method_id: "",
        since: "",
        until: "",
        client_id: "",
        seller_id: "",
        order_id: "",
        web: true,
        currency: Constants.CURRENCIES.DOLARES,
      },
      thead: [],
      trows: [],
      toPrint: null,
      showFilters: false,
      heading: {
        quantity: 0,
        total: 0,
      },
    }));
    await this.getThead();
    await this.load();
  };

  print = (data) => {
    Globals.typeDownloadOptions(
      `Selecciona en que formato deseas que se exporte el reporte`,
      (type_download) => {
        this.setState(state => ({
          form: {
            ...state.form,
            type_download: type_download
          }
        }));
        axios
          .post("admin/reports/orders/download", this.state.form)
          .then(res => {
            if (res.data.url_storage) {
              //const url = res.data.url;
              const url = `${ENV.BasePublic}${res.data.url_storage}`;
              const win = window.open(url, "_blank");
              win.focus();
            }
            else {
              Globals.showError('Ha ocurrido un error');
            }
          })
          .catch(err => {
            Globals.showError();
          });
      }
    );
  };

  selectCurrency = (item) => {
    this.setState({ toPrint: { item, currency: this.props.currency } });
  }

  download = async (currency) => {
    try {
      const form = {
        currency,
        is_copy: true,
        order_id: this.state.toPrint.item.crypt,
        _user_id: this.props.user.id,
      };

      const res = await axios.post('admin/orders/download', form);
      if (!res.data?.url) throw new Error();

      window.open(res.data.url);
      this.setState({ toPrint: null });

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al generar el PDF. Intente nuevamente.');
    }
  }

  cancel = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo de cancelación',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe indicar el motivo');

        const form = {
          comments,
          order_id: item.id,
          user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return axios.post('admin/orders/cancel', form)
          .then(response => {
            if (response.status !== 200) throw new Error(response.statusText);
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al cancelar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha cancelado el pedido correctamente');
        this.getOrders(1);
      }
    });
  }

  delete = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo para eliminar el pedido',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe indicar el motivo');

        const form = {
          comments,
          order_id: item.id,
          user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return axios.post('admin/orders/delete', form)
          .then(response => {
            if (response.status !== 200) throw new Error(response.statusText);
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al eliminar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha eliminado el pedido correctamente');
        this.getOrders(1);
      }
    });
  }

  render() {
    return (
      <Menu history={this.props.history}>
        {!!this.state.toPrint && (
          <Modal
            title="Seleccionar moneda"
            onClose={() => this.setState({ toPrint: null })}
            visible
          >
            <div id="currency" className="d-flex flex-row justify-content-around mb-3">
              <button
                onClick={() => this.download(Constants.CURRENCIES.DOLARES)}
                className="currency-item bg-blue"
              >
                <span className="currency-symbol text-white">$</span>
                <span className="currency-name text-white">Dólar</span>
              </button>

              <button
                onClick={() => this.download(Constants.CURRENCIES.BOLIVARES)}
                className="currency-item bg-blue"
              >
                <span className="currency-symbol text-white">Bs</span>
                <span className="currency-name text-white">Bolívar</span>
              </button>
            </div>
          </Modal>
        )}

        {this.state.showFilters && (
          <RBModal
            tabIndex="0"
            size="lg"
            show
            onHide={() => this.setState({ showFilters: false })}
            backdropClassName="custom-modal-bd"
            contentClassName="custom-modal-content"
            visible
          >
            <RBModal.Body>
              <img
                src={CloseIcon}
                alt="Cerrar"
                className="close-btn"
                onClick={() => this.setState({ showFilters: false })}
              />

              <Filters
                form={this.state.form}
                categories={this.state.categories}
                clients={this.state.clients}
                paymentMethods={this.state.paymentMethods}
                paymentStatuses={this.state.paymentStatuses}
                sellers={this.state.sellers}
                onSearch={async (filters) => {
                  await this.setState(s => ({ showFilters: false, page: 1, form: { ...s.form, ...filters } }));
                  this.load();
                }}
                onClose={() => this.setState({ showFilters: false })}
              />
            </RBModal.Body>
          </RBModal>
        )}

        <div className="row monthly-earnings mb-3" style={{ justifyContent: 'flex-end' }}>
          <div className="col-sm-4">
            <span className="">PEDIDOS REALIZADOS</span>
            <div>{ this.state.heading.quantity }</div>
          </div>
          <div className="col-sm-4">
            <span className="">TOTAL PEDIDOS</span>
            <div>{ Globals.formatMiles(this.state.heading.total) } $</div>
          </div>
        </div>

        <div id="products">
          <Table
            data={this.state.data.length}
            title="Reporte de Ventas"
            header={this.state.thead}
            right={
              <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  title="Filtrar"
                  small="true"
                  onClick={() => this.setState({ showFilters: true })}
                >
                  <img src={FilterIcon} style={{ width: 24, marginTop: '-3px' }} alt="Imprimir" />
                </Button>
                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print(this.state.data)}
                >
                  <img src={PrinterIcon} style={{ width: 24, marginTop: '-3px' }} alt="Imprimir" />
                </Button>
              </div>
            }
          >
            {this.state.data.map((i, index) => (
              <tr key={index}>
                <td>{ i.correlative_number }</td>
                <td>{ i.created_es }</td>
                <td>{ i.seller?.name ?? '' }</td>
                <td>{ i.client?.name ?? '' }</td>
                <td>{ i.product_categories_text }</td>
                <td>{ parseFloat(Number(i.total)).toFixed(2) }</td>
                <td>{ i.payment_methods_text ?? (i.method ? i.method?.name : '') }</td>
                <td>
                  <span style={{ color: 'black' }}>
                    { 
                      i.status !== Constants.ORDER_STATUS.REJECTED && i.status !== Constants.ORDER_STATUS.CANCELED && i.deleted_at === null ? i.status_payment_text : '-' 
                    }
                  </span>
                </td>
              </tr>
            ))}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

const Filters = ({ form, paymentMethods, clients, sellers, categories, paymentStatuses, onSearch }) => {
  const initialFilters = {
    order_id: '',
    payment_method_id: '',
    category_id: '',
    client_id: '',
    seller_id: '',
    since: '',
    until: '',
    status: '',
    status_payment: '',
  };

  const [_form, setForm] = useState({
    order_id: form.order_id ?? '',
    payment_method_id: form.payment_method_id ?? '',
    category_id: form.category_id ?? '',
    client_id: form.client_id ?? '',
    seller_id: form.seller_id ?? '',
    since: !!form.since ? moment(form.since).toDate() : '',
    until: !!form.until ? moment(form.until).toDate() : '',
    status: form.status ?? '',
    status_payment: form.status_payment ?? '',
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const reset = () => {
    onSearch(initialFilters);
  }

  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0 1rem', padding: '1rem 2rem 0' }}>
        <div>
          <DatePicker
            color="white"
            label="Desde"
            className="filter"
            value={_form.since}
            onChange={v => onChange(v, 'since')}
          />
        </div>
        <div>
          <DatePicker
            color="white"
            label="Hasta"
            className="filter"
            value={_form.until}
            onChange={v => onChange(v, 'until')}
          />
        </div>

        <div>
          <Input
            color=" "
            label="ID"
            className="filter"
            value={_form.order_id}
            onChange={v => onChange(v.target.value, 'order_id')}
          />
        </div>

        <div>
          <Select
            color="white"
            label="Método de pago"
            className="filter"
            style={{ textTransform: "capitalize" }}
            onChange={e => onChange(e.target.value, 'payment_method_id')}
            value={_form.payment_method_id}
            options={paymentMethods}
          />
        </div>
        <div>
          <Select
            color="white"
            label="Cliente"
            className="filter"
            truncateLabel={27}
            style={{ textTransform: "capitalize" }}
            onChange={e => onChange(e.target.value, 'client_id')}
            value={_form.client_id}
            options={clients}
          />
        </div>
        <div>
          <Select
            color="white"
            label="Vendedor"
            className="filter"
            style={{ textTransform: "capitalize" }}
            onChange={e => onChange(e.target.value, 'seller_id')}
            value={_form.seller_id}
            options={sellers}
          />
        </div>

        <div>
          <Select
            color="white"
            label="Categoría"
            className="filter"
            style={{ textTransform: "capitalize" }}
            onChange={e => onChange(e.target.value, 'category_id')}
            value={_form.category_id}
            options={categories}
          />
        </div>
        {/* <div>
          <Select
            color="white"
            label="Estatus"
            className="filter"
            style={{ textTransform: "capitalize" }}
            onChange={e => onChange(e.target.value, 'status')}
            value={_form.status}
            options={statuses}
          />
        </div> */}
        <div>
          <Select
            color="white"
            label="Estatus de pago"
            className="filter"
            style={{ textTransform: "capitalize" }}
            onChange={e => onChange(e.target.value, 'status_payment')}
            value={_form.status_payment}
            options={paymentStatuses}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          className="btn-align-bottom btn-filter"
          style={{ minWidth: 110, maxWidth: 110 }} 
          color=" " 
          onClick={() => onSearch(_form)}
        >
          <span>Filtrar</span>
        </Button>
        <Button
          className="btn-align-bottom btn-filter"
          style={{ minWidth: 110, maxWidth: 110, marginRight: 0 }}
          color=" "
          onClick={reset}
        >
          <span>Limpiar</span>
        </Button>
      </div>
    </>
  )
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(Report);
