import React, { createRef } from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import { Globals, ENV, Constants, SocketEvents, axios } from "../../utils";
import { User as UserService } from "../../services";
import BusinessIcon from '../../assets/icons/my-business.png';
import ProfileIcon from '../../assets/icons/my-profile.png';
import PictureIcon from '../../assets/icons/image.png';
import socket from '../../utils/socket';
import { Share } from "../../components";
import TrashIcon from '../../assets/icons/trash.png';

const SHOW_PRICES = {
	YES: 1,
	NO: 0
}

const TABS = {
	Z: 1,
	X: 2
}

class ReportFiscal extends React.Component {

	state = {
		tab: 0,
		form: {
			logo: {},
			logoUrl: '',
			password: '',
			confirmPassword: '',
			name: '',
			email: '',
			document: '',
			phone: '',
			address: '',
			show_prices: SHOW_PRICES.YES,
			url: ENV.BaseStore,
			formattedUrl: '',
			sell_note: '',
			facebook: '',
			instagram: '',
			twitter: '',
			header_bg: '#C70FB1',
			footer_bg: '#333333',
			banner: '',
			bannerUrl: '',
		},
		currentURL: ENV.BaseStore,
		store_session: `${this.props.user.id}-${Date.now()}`,
	};

	componentDidMount() {
		this.assignForm();
		this.load();
	}

	debounce = createRef();

	load = async () => {
		Globals.setLoading();

		try {
			const res = await UserService.getAdminProfile({
				user_id: this.props.user.id
			});

			if (!res.user) throw new Error('Ocurrió un error inesperado');

			await this.setState({
				form: {
					...this.state.form,
					show_prices: res.public_store?.show_prices,
					url: res.public_store?.url || '',
					facebook: res.user.facebook || '',
					instagram: res.user.instagram || '',
					twitter: res.user.twitter || '',
					header_bg: res.public_store?.header_bg || '#C70FB1',
					footer_bg: res.public_store?.footer_bg || '#333333',
					banner: res.public_store?.banner || '',
					bannerUrl: res.public_store?.banner ? Globals.fromPhotos(res.public_store.banner) : '',
				},
				currentURL: ENV.BaseStore + (res.public_store?.url || ''),
			});
			await this.formatUrl();

		} catch (error) {
			console.log(error);
			Globals.showError('Ocurrió un error inesperado');
		}

		Globals.quitLoading();
	}

	assignForm = async () => {
		await this.setState({
			form: {
				...this.state.form,
				logoUrl: this.props.user.logo ? Globals.fromPhotos(this.props.user.logo) : '',
				name: this.props.user?.name || '',
				email: this.props.user?.email || '',
				document: this.props.user?.document || '',
				phone: this.props.user?.phone || '',
				address: this.props.user?.address || '',
				sell_note: this.props.user?.sell_note || '',
				facebook: this.props.user?.facebook || '',
				instagram: this.props.user?.instagram || '',
				twitter: this.props.user?.twitter || '',
			}
		});
	}

	onChangeEvent = async (event) => {
		const target = event.target.name;
		const value = event.target.value;

		await this.setState(state => ({
			form: { ...state.form, [target]: value }
		}));

		if (!['header_bg','footer_bg'].includes(target)) return;

		if (this.debounce.current) clearTimeout(this.debounce.current);
		this.debounce.current = setTimeout(async () => {
			socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.COLORS, {
				header_bg: this.state.form.header_bg,
				footer_bg: this.state.form.footer_bg,
				store_session: this.state.store_session,
			});
		}, 500);
	}

	onChange = async (value, target) => {
		await this.setState(state => ({
			form: {
				...state.form,
				[target]: !!value?.target ? value?.target?.value : value,
			}
		}));
	}

	formatUrl = async () => {
		await this.setState(state => ({
			form: {
				...state.form,
				formattedUrl: ENV.BaseStore + state.form.url,
			}
		}));
	}

	uploadPhoto = async (e) => {
    const currentFile = URL.createObjectURL(e.target.files[0]);
		await this.onChange(e.target.files[0], 'logo');
		await this.onChange(currentFile, 'logoUrl');
	}

	setTab = async (tab) => {
		const user_id = this.props.user.id
		this.setState({ tab });

		if(tab === TABS.X){
			Globals.setLoading();
			await axios.post('admin/orders/fiscal-bill/reportx', {user_id: user_id})
				.then(res => {
					if(res.data?.result){
						Globals.showSuccess('Imprimiendo Reporte X...');
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		}
		else if(tab === TABS.Z){
			Globals.setLoading();
			await axios.post('admin/orders/fiscal-bill/reportz', {user_id: user_id})
				.then(res => {
					if(res.data?.result){
						Globals.showSuccess('Imprimiendo Reporte Z...');
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		}
	}

	onSubmit = async () => {
		if (!this.isValidForm()) return;
		Globals.setLoading();

		try {
			const res = await UserService.editAdminProfile({
				web: true,
				user_id: this.props.user.id,
				...this.state.form,
				hasFile: true,
			});

			if (!res.user) throw new Error('Ocurrió un error inesperado');

			this.props.dispatch({
				type: 'SET_USER',
				payload: res.user,
			});
			Globals.showSuccess('Se han guardado los cambios');

		} catch (error) {
			console.log(error);
			Globals.showError(error?.error || 'Ocurrió un error inesperado');
		}

		Globals.quitLoading();
	}

	isValidForm = () => {
		const onError = (message) => {
			Globals.showError(message);
			return false;
		}

		const { form } = this.state;

		if (!form.email){
			return onError('El correo es obligatorio');
		}

		if (!!form.password || !!form.confirmPassword) {
			if (!form.password)
				return onError('La contraseña es obligatoria');
		
			if (form.password !== form.confirmPassword)
				return onError('Las contraseñas no coinciden');
		}

		if(this.props.user.level_id === Constants.ROLE_ADMIN){
			if (!form.url){
				return onError('La URL es obligatoria');
			}	
	
			if (form.url.includes(' ')){
				return onError('La URL no puede tener espacios en blanco');
			}
		}

		return true;
	}

	shareURL = () => {
		if (!navigator.share)
			return Globals.showError('Tu navegador no permite esta función');

		try {
			navigator.share({
				title: 'Catálogo Virtual',
				url: this.state.form.formattedUrl,
			});

		} catch (error) {
			console.log(error)
		}
	}

	onUploadBanner = async (e) => {
		if (!e.target.files[0]) return;
		if (!['image/jpeg','image/png','image/gif'].includes(e.target.files[0].type))
			return Globals.showError('La imagen debe ser formato JPG o PNG');
		if (e.target.files[0].size > 500000)
			return Globals.showError('La imagen no puede pesar más de 500KB');

    const currentFile = URL.createObjectURL(e.target.files[0]);
		const file = new FileReader();
		file.readAsDataURL(e.target.files[0]);
		file.onload = () => {
			socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, {
				banner: file.result,
				store_session: this.state.store_session,
			});
		}
		await this.onChange(e.target.files[0], 'banner');
		await this.onChange(currentFile, 'bannerUrl');
	}

	onRemoveBanner = async () => {
		await this.onChange('', 'banner');
		await this.onChange('', 'bannerUrl');
		document.getElementById('banner-image').value = null;
		socket.emit(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, {
			banner: null,
			store_session: this.state.store_session,
		});
	}

	render() {

		const { history } = this.props;
		const { form, tab } = this.state;
		const photo = form?.logoUrl || PictureIcon;
		const banner = form?.bannerUrl || PictureIcon;

		return (
			<Menu history={history}>
				<div id="my-profile-account">
                    <main className="account-tab">
                        <nav>
							<button
								className={tab === TABS.X ? 'active' : ''}
								onClick={() => this.setTab(TABS.X)}
							>
								<img src={BusinessIcon} alt="Reporte X" />
								<span>Reporte X</span>
							</button>

							<button
								className={tab === TABS.Z ? 'active' : ''}
								onClick={() => this.setTab(TABS.Z)}
							>
								<img src={BusinessIcon} alt="Reporte Z" />
								<span>Reporte Z</span>
							</button>
                        </nav>
                    </main>
				</div>
			</Menu>
		);
	}
}

const StorePreview = React.memo(({ currentURL, store_session }) => (
	<iframe
		title="Vista previa"
		src={`${currentURL}${Globals.toParams({ store_session })}`}
	/>
))

export default connect(state => {
	return {
		user: state.user
	};
})(ReportFiscal);
