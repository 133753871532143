import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Input, Select, DatePicker, SelectActions, Modal } from "../../components";
import { Constants, Globals, axios, Socket, SocketEvents, ENV } from "../../utils";

import { Branch, Warehouse } from "../../services";
import CreateEditZone from "../zones/create_edit_zone";

class CreateEditSeller extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: "",
      modalZones: false,
      editZone: null,
      form: {
        id: "",
        name: "",
        phone: "",
        document_type: "V",
        document: "",
        level: this.props.user.level,
        email: "",
        password: "",
        password_confirmation: "",
        image: "",
        branch_id: 0,
        zone_id: "",
        zones: [],
        warehouses: [],
        warehouse_id: "",
        goal_amount: '',
        goal_date: '',
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        role: Constants.ROLE_SELLER,
        permissions: [],
        seller_code: "",
      },
      errors: [],
      zones: [],
      branches: [],
      warehouses: [],
      roles: [
        {
          value: Constants.ROLE_ADMIN,
          label: 'Admin'
        },
        {
          value: Constants.ROLE_MODERATOR,
          label: 'Moderador'
        },
        {
          value: Constants.ROLE_SELLER,
          label: 'Vendedor'
        },       
        {
          value: Constants.ROLE_MANAGER,
          label: 'Gerente'
        },       
      ],
      edit: false,
      textButton: "Crear",
      permissions: this.props.permissions,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();

  componentDidMount() {
    const { edit } = this.props;

    this.load();

    if (!edit) {
      return;
    }

    this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    const { user } = this.props;
    const userId = user.id;

    this.getZones();
    this.getWarehouses();

    // Role.getRolesByEnterprise({
    //   Id: userId
    // })
    //   .then(response => {
    //     let rolesId = response.map(({ id, display_name }) => ({
    //       value: id,
    //       label: display_name
    //     }));

    //     this.setState({
    //       roles: rolesId
    //     });
    //   })
    //   .then(() => {
    //     this.getBranches();
    //   })
    //   .catch(() => Globals.showError());
  };

  getZones = () => {
    axios
      .post("web/admin/zones", {user_id: this.props.user.id})
      .then(({ data }) => {
        this.setState({ zones: data.zones });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getBranches = () => {
    const { user } = this.props;

    const enterpriseId = user.id;

    Branch.getBranches({
      role: user.role,
      enterprise_id: enterpriseId
    }).then(response => {
      if (response.result) {
        const branches_filtered = response.branches.data.filter(
          ({ status, deleted_at }) =>
            status === Constants.STATUS_ACTIVE && !deleted_at
        );
        this.setState(state => ({
          ...state,
          branches: branches_filtered,
          form: {
            ...state.form,
            warehouse_id: state.form.warehouse_id ? state.form.warehouse_id : ""
          }
        }));
      }
    });
  };

  getWarehouses = async () => {
      Globals.setLoading();

      try {
        const data = {
          web: true,
          user_id: this.props.user.id,
          is_select: true,
          minimize: true,
        };

        const res = await axios.post('web/admin/warehouses', data);
        if (res.data?.warehouses){
          const formatWarehouses = res.data.warehouses.map(Item => ({
            value: Item.id,
            label: Item.name
          }));

          this.setState({ warehouses: formatWarehouses });
        }
      } catch (error) {
          Globals.showError();
          console.log('GetWarehouses -> load -> catch: ', error);
      }

      Globals.quitLoading();
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    let param = { ...this.state.form };
    param.user_id = this.props.user.id;
    param.web = true;

    Globals.setLoading();
    axios
      .post(
        this.props.edit ? "web/admin/sellers/edit" : "web/admin/sellers/create",
        param
      )
      .then(res => {
        this.setState(this.defaultState);
        let msg = ''
        this.props.edit ? msg = "Vendedor editado" : msg = "Vendedor creado"
        Globals.showSuccess(msg);
        this.props.onClose();
        if (this.props.edit) {
            Socket.emit(SocketEvents.USERS.UPDATE, { id: this.props.edit.element.id });
        }
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.error);
          return;
        }
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    let documents = {};

    const { element } = this.props.edit;
    console.log(element)

    await this.setState({
      form: {
        id: element.id,
        seller_id: element.id,
        zone_id: element.zone_id,
        zones: element.zones?.map(x => x.id) || [],
        name: element.name,
        email: element.email,
        phone: element.phone,
        //document_type: documents.document_type,
        document: element.document,
        level: element.level_id,
        role: element.level_id,
        password: "",
        password_confirmation: "",
        commission: element.commission,
        goal_amount: element.goal?.amount,
        goal_date: moment(element.goal?.date).isValid() ? moment(element.goal?.date).toDate() : '',
        permissions: element?.permissions?.map(x => x.id) || [],
        warehouse_id: element?.warehouse_id || '',
        seller_code: element?.seller_code || '',
        warehouses: element?.warehouses?.map(x => x.warehouse_id) || [],
      },
      edit: true,
      textButton: "Editar"
    });

    // if (Types[user.role].value === Types[3].value) {
    //   this.setState({
    //     ...this.state.form,
    //     role: element.ROLE_SELLER_subuser
    //       ? element.ROLE_SELLER_subuser.ROLE_SELLER_id
    //       : ""
    //   });
    // } else if (Types[user.role].value === Types[4].value) {
    //   this.setState({
    //     ...this.state.form,
    //     role: element.ROLE_SELLER_subuser
    //       ? element.ROLE_SELLER_subuser.ROLE_SELLER_id
    //       : ""
    //   });
    // } else {
    //   this.setState({
    //     ...this.state.form,
    //     level: element.roles[0].id
    //   });
    // }
  };

  showSelect = () => {
    const { user } = this.props;
    let select;

    // if (
    //   Types[user.role].value === Types[1].value ||
    //   Types[user.role].value === Types[2].value
    // ) {
    //   select = (
    //     <div className="col-md-6">
    //       <Select
    //         color=" "
    //         label="Tipo de usuario"
    //         name="level"
    //         labelClass="material"
    //         className="material"
    //         defaultname="Seleccione"
    //         value={this.state.form.level ? this.state.form.level : ""}
    //         options={[
    //           {
    //             label: "Administrador",
    //             value: 2
    //           }
    //         ]}
    //         error={`${this.hasErrorFor("level") ? "is-invalid" : ""}`}
    //         invalidfeedback={this.renderErrorFor("level")}
    //         onChange={this.handleChange}
    //       />
    //     </div>
    //   );
    // } else if (this.state.form.id != user.id) {
    //   select = (
    //     <div className="col-md-6">
    //       <Select
    //         color=" "
    //         label="Rol"
    //         name="role"
    //         labelClass="material"
    //         className="material"
    //         options={this.state.roles}
    //         value={this.state.form.role}
    //         error={`${this.hasErrorFor("role") ? "is-invalid" : ""}`}
    //         invalidfeedback={this.renderErrorFor("role")}
    //         onChange={this.handleChange}
    //       />
    //     </div>
    //   );
    // }

    select = (
      <div className="col-md-6">
        <Select
          color=" "
          label="Rol"
          name="role"
          labelClass="material"
          className="material"
          options={this.state.roles}
          value={this.state.form.role}
          error={`${this.hasErrorFor("role") ? "is-invalid" : ""}`}
          invalidfeedback={this.renderErrorFor("role")}
          onChange={this.handleChange}
        />
      </div>
    );

    return select;
  };

  showSelectWarehouse = () => {
    const { user } = this.props;
    let select;

    const isSeller = Number(this.state.form.role) === Constants.ROLE_SELLER;
    const isAdmin = Number(this.state.form.role) === Constants.ROLE_MODERATOR;

    // if (
    //   (Types[user.role].value === Types[3].value ||
    //   Types[user.role].value === Types[4].value) && this.state.form.id != user.id
    // ) {
    // }

    select = (
      <div className="row">
        <div className="col-md-6">
          <Select
            color=" "
            name="branch_id"
            label="Sucursal"
            labelClass="material"
            className="material"
            //defaultname={isSeller ? 'Seleccione' : 'Todos'}
            defaultname={isAdmin ? 'Todos' : 'Seleccione'}
            defaultvalue='0'
            disabledFirst={isAdmin ? 'false' : 'true'}
            onChange={async e => {
              await this.handleChange(e);
              await this.getWarehouses(this.state.form.branch_id);
            }}
            value={this.state.form.branch_id}
            options={this.state.branches.map(i => {
              return {
                value: i.id,
                label: i.name
              };
            })}
          />
        </div>
        <div className="col-md-6">
          <Select
            color=" "
            name="warehouse_id"
            label="Almacén"
            labelClass="material"
            className="material"
            // defaultname={isSeller ? 'Seleccione' : 'Todos'}
            // disabledFirst={isSeller ? 'true' : 'false'}
            defaultname={isAdmin ? 'Todos' : 'Seleccione'}
            disabledFirst={isAdmin ? 'false' : 'true'}
            onChange={this.handleChange}
            value={this.state.form.warehouse_id}
            options={this.state.warehouses.map(i => {
              return {
                value: i.id,
                label: i.name
              };
            })}
          />
        </div>
      </div>
    );

    return select;
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  togglePermission = (id) => {
    const { form } = this.state;
    const exists = form.permissions.includes(id);
    if (exists) {
      const others = form.permissions.filter(x => x !== id);
      this.setState({ form: { ...form, permissions: others } });
    } else {
      const permissions = [...form.permissions, id];
      this.setState({ form: { ...form, permissions } });
    }
  };

  toggleWarehouse = (id) => {
    const { form } = this.state;
    const exists = form.warehouses.includes(id);
    if (exists) {
      const others = form.warehouses.filter(x => x !== id);
      this.setState({ form: { ...form, warehouses: others } });
    } else {
      const warehouses = [...form.warehouses, id];
      this.setState({ form: { ...form, warehouses } });
    }
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  };

  createZone = () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({ modalZones: true, editZone: null });
  };

  editZone = (item, index) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({
      modalZones: true,
      editZone: {
        element: item,
        index: index,
      }
    })
  };

  deleteZone = (item) => {
		Globals.confirm('¿Desea eliminar la zona: ' + item.name + '?', () => {
			Globals.setLoading("Eliminando...");
			axios.post('web/admin/zones/delete', { zone_id: item.id })
				.then(res => {
					Globals.showSuccess('zona eliminada con exito');
					this.getZones();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
  };

  removeZone = (zoneId) => {
    this.handleChange({
      target: { value: this.state.form.zones.filter(x => x !== zoneId), name: 'zones' }
    });
  }

	close = async (reloading = false) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({ modalZones: false, editZone: null });
		if (reloading) await this.getZones();
	}

  render() {
    const { submitted, permissions } = this.state;
    const textNameField = this.props.user.role === 1 ? "Nombre/Razón Social" : "Nombre y apellido";
    const sameUser = this.state.form.id === this.props.user.id

    return (
      <form onSubmit={this.handleSubmit}>
        <Modal
          title={this.state.edit ? 'Editar zona' : "Nueva zona"}
          onClose={() => this.close()}
          visible={this.state.modalZones}
        >
          <CreateEditZone
            user={this.props.user}
            edit={this.state.editZone}
            onClose={() => this.close(true)}
          />
        </Modal>

        {this.state.modalZones && (
          <div
            className="backdrop"
            onClick={() => this.close()}
          />
        )}
        <div className="container-create-edit-user">
          <div className="row">
            {/* <div className="col-md-6">
              <Input
                type="text"
                color=" "
                value={this.state.form.name}
                name="name"
                label="Nombre de usuario"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("name")}
                onChange={this.handleChange}
              />
            </div> */}
            <div className="col-md-6">
              <Input
                type="text"
                color=" "
                value={this.state.form.name}
                name="name"
                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("name")}
                label={textNameField}
                className="material"
                labelClass="material"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6">
              <Input
                type="text"
                color=" "
                value={this.state.form.seller_code}
                name="seller_code"
                error={`${this.hasErrorFor("seller_code") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("seller_code")}
                label={"Código (Opcional)"}
                className="material"
                labelClass="material"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                color=" "
                value={this.state.form.phone}
                name="phone"
                type="text"
                maxLength={11}
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                label="Teléfono"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("phone") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("phone")}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6">
              <div style={{ display: "flex" }}>
                <Select
                  color=" "
                  label="Tipo"
                  name="document_type"
                  className="material"
                  labelClass="material"
                  options={Constants.TYPE_DOCUMENTS}
                  value={this.state.form.document_type}
                  error={`${
                    this.hasErrorFor("document_type") ? "is-invalid" : ""
                  }`}
                  invalidfeedback={this.renderErrorFor("document_type")}
                  onChange={this.handleChange}
                />
                <Input
                  color=" "
                  value={this.state.form.document}
                  type="text"
                  name="document"
                  label="Cédula"
                  className="material"
                  labelClass="material"
                  maxLength={this.state.form.document_type === "J" ? 9 : 8}
                  onKeyPress={e => {
                    Globals.soloNumeros(e);
                  }}
                  error={`${this.hasErrorFor("document") ? "is-invalid" : ""}`}
                  invalidfeedback={this.renderErrorFor("document")}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            {/* {this.showSelect()} */}

            <div className={ sameUser ? 'col-md-12' : "col-md-6"}>
              <Input
                color=" "
                type="email"
                value={this.state.form.email}
                name="email"
                label="Email"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("email") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("email")}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6">
              <Input
                color=" "
                type="number"
                value={this.state.form.commission}
                name="commission"
                label="Comisión"
                step="0.01"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("commission") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("commission")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            {/* {this.showSelect()} */}

            <div className="col-md-6">
              <Input
                color=" "
                type="number"
                value={this.state.form.goal_amount}
                name="goal_amount"
                label="Meta ($)"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("goal_amount") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("goal_amount")}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6">
                <DatePicker
                  color="white"
                  label="Fecha de meta (a partir de)"
                  labelClass="material"
                  className="material"
                  value={this.state.form.goal_date}
                  onChange={value => {
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        goal_date: value
                      }
                    }));
                  }}
                />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <SelectActions
                label="Zonas"
                placeholder="Seleccione"
                actionOptions={[
                  {
                    onClick: () => this.createZone(),
                    content: (
                      <div>
                        <span>Añadir</span>
                        <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                      </div>
                    ),
                  }
                ]}
                options={this.state.zones.map((i, idx) => ({
                  value: i.id,
                  label: i.name,
                  actions: [
                    <i
                      key={`zone-${i.value}-edit`}
                      className="fa fa-regular fa-edit"
                      title="Editar"
                      onClick={() => this.editZone(i, idx)}
                    />,
                    <i
                      key={`zone-${i.value}-close`}
                      className="fa fa-regular fa-close"
                      title="Eliminar"
                      onClick={() => this.deleteZone(i, idx)}
                      />,
                  ],
                }))}
                value={this.state.form.zones}
                onChange={v => {
                  if (this.state.form.zones.includes(v)) return;
                  this.handleChange({
                    target: { value: [ ...this.state.form.zones, v ], name: 'zones' }
                  });
                }}
              />

              <ul className="zones">
                {this.state.form.zones.map(zoneId => {
                  const details = this.state.zones.find(x => x.id === zoneId);
                  return (
                    <li onClick={() => this.removeZone(zoneId)} title="Click para remover">
                      { details?.name || `Zona indefinida (id: ${zoneId})` }
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Input
                color=" "
                value={this.state.form.password}
                name="password"
                type="password"
                autoComplete="off"
                label="Contraseña"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("password") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("password")}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md">
              <Input
                color=" "
                value={this.state.form.password_confirmation}
                name="password_confirmation"
                type="password"
                autoComplete="off"
                label="Confirmar Contraseña"
                className="material"
                labelClass="material"
                error={`${
                  this.hasErrorFor("password_confirmation") ? "is-invalid" : ""
                }`}
                invalidfeedback={this.renderErrorFor("password_confirmation")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* <File
            placeholder={
              this.state.edit
                ? "Cambiar imagen de Perfil (opcional)"
                : "Agregar Imagen de Perfil (opcional)"
            }
            placeholdersuccess={
              typeof this.state.form.image == "string"
                ? "Cambiar imagen (Opcional)"
                : "Imagen Agregada"
            }
            showcheck={true.toString()}
            onChange={this.handleChange}
            name="image"
            value={this.state.form.image}
            inputstyle={{
              display: "contents"
            }}
            className="btn-product"
          /> */}

          <div><b>Permisos</b></div>
          <>
            <label>Facturar / Visualizar existencia de Almacén</label>
            <ul style={{ paddingLeft: 0 }}>
              {this.state.warehouses.map(x => (
                <PermissionItem
                  label={x.label}
                  isSelected={this.state.form.warehouses.includes(x.value)}
                  onPress={() => this.toggleWarehouse(x.value)}
                />
              ))}
            </ul>
          </>

          <ul style={{ paddingLeft: 0 }}>
            {permissions.map(x => (
              <PermissionItem
                label={x.name}
                isSelected={this.state.form.permissions.includes(x.id)}
                onPress={() => this.togglePermission(x.id)}
              />
            ))}
          </ul>

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button" className="d-flex justify-content-center">
              <Button color=" " type="submit" className="primary" disabled={submitted}>
                <span>{this.state.textButton}</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const PermissionItem = ({ label, isSelected, onPress }) => (
  <li onClick={() => onPress()} className={`permission-item ${isSelected ? 'selected' : ''}`}>
      { label }
      <div className="checkbox" />
  </li>
);

CreateEditSeller.defaultState = {
  form: {
    id: "",
    name: "",
    phone: "",
    email: "",
    document_type: "V",
    document: "",
    level: "",
    role: "",
    email: "",
    password: "",
    password_confirmation: "",
    image: "",
    commission: "",
    goal_amount: "",
    goal_date: "",
  }
};

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditSeller);
