import axios from "axios";

import Constants from "./constants";
import { Auth } from "../services";

// Enviroment
import ENV from "./env";

// var CancelToken = axios.CancelToken;
// var source = CancelToken.source();

let _axios = axios.create({
  baseURL: ENV.BaseURI
  // cancelToken: source.token
});

_axios.interceptors.request.use(request => {
  const token = localStorage.getItem("token");

  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return request;
});

_axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { config, response } = error;
    const { status, data } = response;

    return new Promise(function(resolve, reject) {
      if (
        status === Constants.STATUS_RESPONSE_SERVER &&
        data.message === "The token has been blacklisted"
      ) {
        localStorage.removeItem("token");
      } else if (
        status === Constants.STATUS_RESPONSE_UNAUTHORIZED &&
        data.message === "Token not provided"
      ) {
        localStorage.removeItem("token");
      } else if (
        status === Constants.STATUS_RESPONSE_UNAUTHORIZED &&
        data.message === "Token has expired"
      ) {
        const retryOriginalRequest = Auth.refresh(({ token }) => {
          config.headers.common["Authorization"] = "Bearer " + token;
          resolve(axios(config));
        });
        return retryOriginalRequest;
      }

      localStorage.removeItem("token");

      return reject(error);
    });
  }
);

function createFormData(data) {
  var formdata = new FormData();
  for (var key in data) {
    formdata.append(key, data[key]);
  }
  return formdata;
}

_axios.all = axios.all;
_axios.spread = axios.spread;

_axios.upload = (url, data) => {
  return _axios.post(url, createFormData(data), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export default _axios;
