import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Modal,
  DatePicker,
  Icon,
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import AddIcon from '../../assets/icons/add.png';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';
import ModalCreatePayment from "./modal-create-payment";
import PrinterIcon from '../../assets/icons/printer.png';

const tableHeader = [
  'Fecha', 
  'N° Pedidos Pagados', 
  'Vendedor', 
  'Comisión Pagada', 
  'Gerente', 
  'Comisión Pagada',
  //'Monto Por Pagar', 
  'Monto Total Pagado', 
  //'Monto Total', 
  'Acciones'
];

class CommisionsPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleAddPayment: false,
      data: [],
      sellers: [],
      form: {
        web: true,
        seller_id: "",
        since: "",
        until: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        user_id: this.props.user.id,
      },
      pagination: {
        page: 1,
        last_page: 1,
      },
      totals: {
        monto_total: 0,
        total_comisiones: 0,
        total_cobrar: 0,
      },
      modal: {
        paid_amount: '',
        commission_to_pay: '',
      },
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (page = 1) => {
    const {form} = this.state
    form.page = page
    axios
      .post('web/admin/commission-payments', form)
      .then(res => {
        this.setState({
          page,
          data: res.data.payments.data,
          sellers: res.data.sellers,
          last_page: res.data.payments.last_page,
        });
      })
      .catch(err => {
        console.log('Commissions -> load -> catch: ', err);
      });
  };

  delete = async (item) => {
    Globals.confirm("¿Desea eliminar el pago?", () => {
      Globals.setLoading();
      axios
        .post(`web/admin/commission-payments/delete`, { commission_payment_id: item.id })
        .then(res => {
          Globals.showSuccess('Se ha eliminado el pago correctamente');
          this.load();
        })
        .catch(err => {
          Globals.showError();
          console.log(err)
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  change = async (e) => {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      form: {
        web: true,
        seller_id: "",
        since: "",
        until: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        user_id: this.props.user.id,
      },
    }));
    await this.load();
  };

  togglePaymentModal = () => {
    this.setState(state => ({ visibleAddPayment: !state.visibleAddPayment }));
    this.load()
  };

  goBack = () => {
    this.props.history.replace('/commissions');
  };

  print = () => {
    axios
      .post('web/admin/commission-payments/download', {
        ...this.state.form,
        user_id: this.props.user.id,
        web: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Clientes -> download -> catch: ', err);
      });
  }

  render() {

    const { data, form, visibleAddPayment } = this.state;

    return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={() => this.goBack()} />}
      >
        <Modal
          title="Agregar Pago de Comisión"
          onClose={() => this.togglePaymentModal()}
          visible={visibleAddPayment}
        >
          <ModalCreatePayment
            sellers={this.state.sellers}
            onClose={() => this.togglePaymentModal()}
          />
        </Modal>

        <div id="commissions-payments">
          <div className="filters">
            <div>
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={form.seller_id}
                options={this.state.sellers.map(Item => ({
                  value: Item.id,
                  label: Item.name
                }))}
              />
            </div>
            <div>
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                value={form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div>
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                value={form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>

            <div>
              <Button className="btn-align-bottom btn-filter" color=" " onClick={() => this.load()}>
                <span>Filtrar</span>
              </Button>
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>
          </div>

          {/* {!!data.length && (
            <div className="row monthly-earnings mb-3 d-flex">
              <div className="col-4 col-lg-3 total-amount">
                <div className="totals">
                  <span>Total:</span>
                  <span>$ {Globals.formatMiles(this.state.totals.monto_total)} </span>
                  <span>Pagado:</span>
                  <span>$ {Globals.formatMiles(this.state.totals.monto_total)}</span>
                </div>
              </div>
            </div>
          )} */}

          <Table
            data={this.state.data.length}
            title="Pago de Comisiones"
            header={tableHeader}
            right={
              <div>
                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print()}
                >
                  <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                </Button>
                <Button
                  title="Agregar pago"
                  small="true"
                  onClick={() => this.togglePaymentModal()}
                  disabled={!this.state.sellers.length}
                >
                  <img src={AddIcon} style={{ width: 24 }} alt="Agregar pago" />
                </Button>
              </div>
            }
          >
            {this.state.data.map((i, index) => {
              let amount_seller = i.amount_seller !== null ? i.amount_seller
                :
                i.orders[0]?.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((i.orders[0]?.total - i.orders[0]?.to_pay) / i.orders[0]?.total) * i.orders[0]?.commission) : i.orders[0]?.commission;

              let amount_manager = i.amount_manager !== null ? i.amount_manager
                :
                i.orders[0]?.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((i.orders[0]?.total - i.orders[0]?.to_pay) / i.orders[0]?.total) * i.orders[0]?.commission_manager) : i.orders[0]?.commission_manager;

              let total_pagado = amount_seller + amount_manager;
              return (
                <tr key={index}>
                  <td>{ moment(i.date).format('DD-MM-YYYY') }</td>
                  <td>
                    { 
                      i.orders.map(order => order.number_format).join(', ')
                    }
                  </td>
                  <td>{ i.user?.name }</td>                  
                  <td> 
                    {
                      // i.amount_seller !== null ? Globals.formatMiles(i.amount_seller, true)
                      // :
                      // Globals.formatMiles(i.orders[0]?.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((i.orders[0]?.total - i.orders[0]?.to_pay) / i.orders[0]?.total) * i.orders[0]?.commission) : i.orders[0]?.commission, true)
                      Globals.formatMiles(amount_seller, true)
                    }
                  </td>
                  <td>{ i.user?.managers[0]?.name }</td>
                  <td> 
                    {
                      // i.amount_manager !== null ? Globals.formatMiles(i.amount_manager, true)
                      // :
                      // Globals.formatMiles(i.orders[0]?.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((i.orders[0]?.total - i.orders[0]?.to_pay) / i.orders[0]?.total) * i.orders[0]?.commission_manager) : i.orders[0]?.commission_manager, true)
                      Globals.formatMiles(amount_manager, true)
                    }
                  </td>
                  <td>
                    { 
                      //Globals.formatMiles(i.orders[0].total_commission_paid, true)
                      Globals.formatMiles(total_pagado, true)
                    }
                  </td>
                  <td>
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(i)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.pagination.last_page}
            active={this.state.pagination.page}
            onChange={async page => {
              this.load(page);
            }}
          />

        </div>
      </Menu>
    );
  }
}

const BackButton = ({ onClick }) => (
  <>
    <div className="back-button">
      <img src={LeftArrowIcon} onClick={() => onClick()} alt="Regresar" />
    </div>
    <div className="content-breadcumbs">
      <div className="title">Pago de Comisiones</div>
    </div>
  </>
)

export default connect(state => ({
  user: state.user
}))(CommisionsPayments);
